import React, { useState, useEffect } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { callHistoryUserStart, videoCallPayByWalletStart } from '../../../store/actions/PrivateCallAction';
import AddCardModalSec from './AddCardModalSec';
import PaymentMethodCard from './PaymentMethodCard';
import PaymentModelMsgSec from './PaymentModelMsgSec';
import { t } from 'react-multi-lang';
import { connect } from 'react-redux';

const VideoCallPaymentModal = (props) => {
  const nullData = ["", null, undefined, "light"];
  const [skipRender, setSkipRender] = useState(true);

  const [paymentType, setPaymentType] = useState(localStorage.getItem("default_payment_method"));
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);

  const paypalOnError = (err) => { };
  const paypalOnSuccess = (payment) => { };
  const paypalOnCancel = (data) => { };

  const handleSubmit = () => {
    if (paymentType === "WALLET")
      props.dispatch(
        videoCallPayByWalletStart({
          video_call_request_id: props.video_call_request_id,
        })
      );
  };

  useEffect(() => {
    if (!skipRender && !props.videoCallPayByWallet.loading && Object.keys(props.videoCallPayByWallet.data).length > 0) {
      props.dispatch(callHistoryUserStart());
      props.closepaymentsModal();
    }
    setSkipRender(false);
  }, [props.videoCallPayByWallet]);

  return (
    <>
      <div className="payment-modal-sec">
        <Modal
          className={`modal-dialog-center user-list-free-modal payment-modal-res ${nullData.includes(localStorage.getItem("theme"))
            ? ""
            : "dark-theme-modal"
            }`}
          size="xl"
          centered
          show={props.paymentsModal}
          onHide={props.closepaymentsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("video_call")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="wallet-card-body">
            <div className="payment-modal-body">
              <Row className="justify-content-between">
                <PaymentMethodCard
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                  setShowAddCard={setShowAddCard}
                  payAmount={props.callDetails.amount}
                />
                <Col md={12} xl={5}>
                  {showAddCard ? (
                    <AddCardModalSec setShowAddCard={setShowAddCard} />
                  ) : (
                    <PaymentModelMsgSec
                      title={t("video_call_payment")}
                      message={t("subscription_payment_note")}
                      paymentType={paymentType}
                      amount_formatted={props.callDetails.amount_formatted}
                      amount={props.callDetails.amount}
                      payNowAction={handleSubmit}
                      paypalOnError={paypalOnError}
                      paypalOnSuccess={paypalOnSuccess}
                      paypalOnCancel={paypalOnCancel}
                      btnDisable={
                        props.videoCallPayByWallet.ButtonDisable
                      }
                      btnText={
                        props.videoCallPayByWallet.loadingButtonContent
                          ? props.videoCallPayByWallet.loadingButtonContnet
                          : t("pay")
                      }
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const mapStateToPros = (state) => ({
  videoCallPayByWallet: state.privateCall.videoCallPayByWallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(VideoCallPaymentModal);