import React, { useEffect, useState } from 'react';
import { Form, Button, Image, Modal, Tab, Row, Col, Nav } from "react-bootstrap";
import { useDropzone } from 'react-dropzone'
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { Form as FORM, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import { savePostAlbumStart } from "../../../store/actions/PostAlbumAction";

const AddNewFolderModal = (props) => {
  const { selectedPayPerView } = props;

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const [skipRender, setSkipRender] = useState(true);


  const thumbs = files.map(file => (
    <div className='thumb' key={file.name}>
      <div className='thumb-inner'>
        <img
          src={file.preview}
          className="thumb-img"
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
      <Image
        className="thumb-close-img"
        src={window.location.origin + "/assets/images/new-chat/modal-close.svg"}
        onClick={() => {
          if (window.confirm(t("confirm_remove_thumb"))) {
            setFiles([]);
          }
        }}
      />
    </div>
  ));

  const nullData = ["", null, undefined, "light"];

  const handleSubmit = (values) => {
    let finalData = values;
    if (selectedPayPerView) finalData = { ...finalData, post_album_id: selectedPayPerView.post_album_id };
    if (files.length > 0) finalData = { ...finalData, thumbnail: files[0] };
    props.dispatch(savePostAlbumStart(finalData));
  };

  const newFolderSchema = Yup.object().shape({
    name: Yup.string().required("Title is required"),
    thumbnail: Yup.string(),
    description: Yup.string(),
    amount: Yup.number("Invalid Format").required("Enter Amount"),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveAlbum.loading &&
      Object.keys(props.saveAlbum.data).length > 0
    ) {
      props.closeAddNewFolderModal();
    }
    setSkipRender(false);
  }, [props.saveAlbum]);


  return (
    <>
      <Modal
        className={`modal-dialog-center add-new-content-modal
                    ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
          }
                `}
        size="lg"
        centered
        show={props.addNewFolder}
        onHide={props.closeAddNewFolderModal}
      >
        {props.addNewFolder === true ? (
          <Formik
            initialValues={{
              name: selectedPayPerView ? selectedPayPerView.name : "",
              description: selectedPayPerView ? selectedPayPerView.description : "",
              amount: selectedPayPerView ? selectedPayPerView.actual_amount : "",
              thumbnail: "",
            }}
            validationSchema={newFolderSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched, setFieldValue, resetForm, values }) => (
              <FORM>
                <Modal.Header closeButton>
                  <Modal.Title>{selectedPayPerView ? t("edit_folder") : t("create_new_folder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="create-folder-form">
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {t("folder_name")}
                          </Form.Label>
                          <Field
                            type="text"
                            placeholder={t("folder_name")}
                            name="name"
                            className={`no-padding form-control ${touched.name && errors.name ? "is-invalid" : ""
                              }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="name"
                            className="invalid-feedback mt-3"
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label className="choose-payment-label">
                            {t("description")}
                          </Form.Label>
                          <Field
                            as="textarea"
                            rows={5}
                            name="description"
                            placeholder={t("description")}
                            className={`no-padding form-control height-auto ${touched.description && errors.description
                              ? "is-invalid"
                              : ""
                              }`}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="choose-payment-label">
                            {configuration.get(
                              "configData.is_only_wallet_payment"
                            ) == 1
                              ? t("token")
                              : t("amount")}
                          </Form.Label>
                          <Field
                            type="number"
                            min="0"
                            step="any"
                            className={`no-padding form-control ${touched.amount && errors.amount
                              ? "is-invalid"
                              : ""
                              }`}

                            name="amount"
                          />
                          <ErrorMessage
                            component="div"
                            name="amount"
                            className="invalid-feedback mt-3"
                          />
                        </Form.Group>
                        {/* <div className="mb-3">
                                                    <div className="select-amount-box">
                                                        <div className="select-amount-card active">
                                                            +10
                                                        </div>
                                                        <div className="select-amount-card">
                                                            +20
                                                        </div>
                                                        <div className="select-amount-card">
                                                            +50
                                                        </div>
                                                        <div className="select-amount-card">
                                                            +100
                                                        </div>
                                                    </div>
                                                </div> */}
                        <Form.Group className="mb-5">
                          <Form.Label>Upload Thumbnail</Form.Label>
                          <div className="upload-thumbnail-card">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="upload-thumbnail-item">
                                <Image
                                  className="upload-thumbnail-img"
                                  src={
                                    window.location.origin + "/assets/images/my-profile/image-blue.svg"
                                  }
                                />
                                <p>{t('upload_thumbnail_image')}</p>
                                <h6>{t('upload_thumbnail_image_note')}</h6>
                              </div>
                            </div>
                            <aside className='thumbs-container'>
                              {thumbs}
                              {selectedPayPerView && thumbs.length == 0 ?
                                <div className='thumb' >
                                  <div className='thumb-inner'>
                                    <img
                                      src={selectedPayPerView.thumbnail}
                                      className="thumb-img"
                                    />
                                  </div>
                                </div>

                                : null}
                            </aside>
                          </div>
                        </Form.Group>


                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="add-new-content-btn-sec">

                    <Button
                      type="submit"
                      className="add-new-content-btn"
                      disabled={props.saveAlbum.buttonDisable}
                    >
                      {props.saveAlbum.loadingButtonContent !== null
                        ? props.saveAlbum.loadingButtonContent
                        : t("add_folder")}
                    </Button>
                  </div>
                </Modal.Footer>
              </FORM>
            )}
          </Formik>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveAlbum: state.postAlbum.saveAlbum,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddNewFolderModal));