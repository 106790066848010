import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Tab,
  Nav,
  Media,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NewProfile.css";
import { fetchPostsStart } from "../../../store/actions/PostAction";
import {
  fetchUserDetailsStart,
  updateVerifyBadgeStatusStart,
} from "../../../store/actions/UserAction";
import { connect } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { translate, t } from "react-multi-lang";
import ProfileSinglePost from "../../helper/ProfileSinglePost";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProfileLoader from "../../Loader/ProfileLoader";
import ProfileSidebar from "./ProfileSidebar";
import PayPerView from "./PayPerView";
import AllPost from "./AllPost";
import { fetchPostAlbumForOwnerStart } from "../../../store/actions/PostAlbumAction";

const ProfileIndex = (props) => {
  const [badgeStatus, setBadgeStatus] = useState(0);

  const [activeSec, setActiveSec] = useState("all");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(12);

  useEffect(() => {
    props.dispatch(fetchPostsStart({ type: "all", skip: 0, take: take }));
    props.dispatch(fetchPostAlbumForOwnerStart({ skip: 0, take: 12 }));
    setSkip(take);
    if (props.profile.loading) {
      props.dispatch(fetchUserDetailsStart());
      setBadgeStatus(localStorage.getItem("is_verified_badge"));
    }
  }, []);

  const setActiveSection = (event, key) => {
    setActiveSec(key);
    if (key != "payperview") {
      props.dispatch(
        fetchPostsStart({
          type: key,
          skip: 0,
          take: take,
        })
      );
      if (key == "all") {
        props.dispatch(fetchPostAlbumForOwnerStart({ skip: 0, take: 12 }));
      }
      setSkip(take);
    }
  };

  const fetchMorePost = () => {
    props.dispatch(
      fetchPostsStart({
        type: activeSec,
        append: true,
        skip: skip,
        take: take,
      })
    );
    setSkip(skip + take);
  };

  const fetchMorePostAlbum = () => {
    props.dispatch(
      fetchPostAlbumForOwnerStart({
        append: true,
        skip: props.userPostAlbum.data.post_albums.length,
        take: 12,
      })
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="new-home-sec">
        <Container>
          {props.profile.loading ? (
            <ProfileLoader />
          ) : (
            <div>
              <div className="my-profile-new-header">
                {/* <Link
                                to={"#"}
                                onClick={() => props.history.goBack()}
                            >
                                <Image
                                    src={
                                        window.location.origin +
                                        "/assets/images/my-profile/back-arrow-btn.svg"
                                    }
                                    className="svg-clone"
                                />

                            </Link> */}
                <h1>{t("my_profile")}</h1>
              </div>
              <div className="new-home-box">
                <ProfileSidebar profile={props.profile} />
                <div className="new-home-main-wrapper">
                  <div className="user-cover-img-sec">
                    <Image
                      className="user-cover-img"
                      src={props.profile.data.cover}
                      alt={props.profile.data.name}
                    />
                    <div className="website-hide-sec">
                      {props.profile.data.featured_story ? (
                        <a
                          data-fancybox="gallery"
                          href={props.profile.data.featured_story}
                        >
                          <Image
                            src={props.profile.data.picture}
                            alt={props.profile.data.name}
                            className="single-profile-user-img border-red"
                          />
                        </a>
                      ) : (
                        <Image
                          src={props.profile.data.picture}
                          alt={props.profile.data.name}
                          className="single-profile-user-img"
                        />
                      )}
                    </div>
                  </div>
                  <div className="user-right-content-sec">
                    <div className="user-right-info">
                      {props.profile.data.about_formatted ? (
                        <>
                          <h3>{t("about_me")}</h3>
                          <div className="user-info-desc">
                            <p>
                              {props.profile.data.about_formatted}
                              {/* <a href="#">Read More</a> */}
                            </p>
                          </div>
                        </>
                      ) : null}
                      <div className="user-info-list">
                        <ul className="list-unstyled">
                          {props.profile.data.selected_category &&
                            props.profile.data.selected_category.name && (
                              <Media as="li">
                                <Link to="#">
                                  <Image
                                    className="user-info-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/fashion.svg"
                                    }
                                  />
                                  <span>
                                    {props.profile.data.selected_category.name}
                                  </span>
                                </Link>
                              </Media>
                            )}
                          {props.profile.data.date_of_birth && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/date-icon.svg"
                                  }
                                />
                                <span>{props.profile.data.date_of_birth}</span>
                              </Link>
                            </Media>
                          )}
                          {props.profile.data.gender && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/gender.svg"
                                  }
                                />
                                <span>
                                  {props.profile.data.gender_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {props.profile.data.eyes_color_formatted && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/eye.svg"
                                  }
                                />
                                <span>
                                  {props.profile.data.eyes_color_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {props.profile.data.height_formatted && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/scale.svg"
                                  }
                                />
                                <span>
                                  {props.profile.data.height_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {props.profile.data.weight_formatted && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/weight.svg"
                                  }
                                />
                                <span>
                                  {props.profile.data.weight_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="user-subscription-plans-details">
                                        <h3>{t('my_plans')}</h3>
                                        {props.profile.data.payment_info?.is_free_account == "0" ?
                                            <div className="user-subscription-btn-sec">
                                                <div className="profile-subscription-btn">
                                                    {props.profile.data.payment_info.subscription_info.monthly_amount_formatted} /Month
                                                </div>
                                                <div className="profile-subscription-btn">
                                                    {props.profile.data.payment_info.subscription_info.yearly_amount_formatted} /Year
                                                </div>
                                            </div>
                                            : <div className="user-subscription-btn-sec">
                                                <div className="profile-subscription-btn">
                                                    {t("free_subscription")}
                                                </div>
                                            </div>
                                        }
                                    </div> */}
                  </div>
                  <div className="mobile-display">
                    <div className="sidebar-links">
                      <ul className="list-unstyled">
                        <Media as="li">
                          <Link to={"/edit-profile"}>
                            <span>
                              <Image
                                className="sidebar-links-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/new-home/icon/edit-profile-theme.svg"
                                }
                              />
                            </span>
                            {t("edit_profile")}
                          </Link>
                        </Media>
                        {/* <Media as="li">
                          <Link to={"/live-videos"}>
                            <span>
                              <Image
                                className="sidebar-links-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/new-home/icon/live-video-theme.svg"
                                }
                              />
                            </span>
                            {t("live_video")}
                          </Link>
                        </Media> */}
                        {/* <Media as="li">
                                                    <Link to="#" onClick={handleShareClick}>
                                                        <span>
                                                            <Image
                                                                className="sidebar-links-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/share-theme.svg"
                                                                }
                                                            />
                                                        </span>
                                                        {t("share")}
                                                    </Link>
                                                </Media> */}
                        {props.profile.data.is_content_creator == 2 ? (
                          <Media as="li">
                            <Link to="/dashboard">
                              <span>
                                <Image
                                  className="sidebar-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/dashboard-theme.svg"
                                  }
                                />
                              </span>
                              {t("dashboard")}
                            </Link>
                          </Media>
                        ) : (
                          <Media as="li">
                            <Link to="/become-a-content-creator">
                              <span>
                                <Image
                                  className="sidebar-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/become-content-creator.svg"
                                  }
                                />
                              </span>
                              {t("become_a_content_creator")}
                            </Link>
                          </Media>
                        )}
                      </ul>
                    </div>
                    {/* {props.profile.data.youtube_link ||
                                            props.profile.data.pinterest_link ||
                                            props.profile.data.linkedin_link ||
                                            props.profile.data.snapchat_link ||
                                            props.profile.data.twitter_link ||
                                            props.profile.data.instagram_link ||
                                            props.profile.data.amazon_wishlist ||
                                            props.profile.data.facebook_link ||
                                            props.profile.data.twitch_link ||
                                            props.profile.data.website ?
                                            <div className="sidebar-social-links">
                                                <ul className="list-unstyled">
                                                    {props.profile.data.youtube_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.youtube_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/you-tube.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.pinterest_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.pinterest_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/pintrest.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.linkedin_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.linkedin_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/linked-in.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.snapchat_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.snapchat_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/snap-chat.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.twitter_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.twitter_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/twitter.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.instagram_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.instagram_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/instagram.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.amazon_wishlist && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.amazon_wishlist} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/amazon.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.facebook_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.facebook_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/facebook.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.twitch_link && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.twitch_link} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/twitch.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                    {props.profile.data.website && (
                                                        <Media as="li">
                                                            <a href={props.profile.data.website} target="_blank">
                                                                <Image
                                                                    className="sidebar-social-links-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/website.png"
                                                                    }
                                                                />
                                                            </a>
                                                        </Media>
                                                    )}
                                                </ul>
                                            </div> : null
                                        } */}
                  </div>
                  <div className="profile-tab-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills" className="grid-four-col">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="all"
                                onClick={(event) =>
                                  setActiveSection(event, "all")
                                }
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/all-post-1.svg"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">
                                  {t("all")}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="free"
                                onClick={(event) =>
                                  setActiveSection(event, "free")
                                }
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/image-post-1.svg"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">
                                  {t("free")}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="hidden"
                                onClick={(event) =>
                                  setActiveSection(event, "hidden")
                                }
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/video-post-1.svg"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">
                                  {t("hidden")}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                                        <Nav.Link eventKey="audio"
                                                            onClick={(event) =>
                                                                setActiveSection(event, "audio")
                                                            }>
                                                            <span>
                                                                <Image
                                                                    className="profile-post-tab-icon"
                                                                    src={
                                                                        window.location.origin + "/assets/images/new-home/icon/audio-post-1.svg"
                                                                    }
                                                                />
                                                            </span>
                                                            <span className="resp-display-none">Musics</span>
                                                        </Nav.Link>
                                                    </Nav.Item> */}
                            {localStorage.getItem("is_content_creator") ==
                              2 && (
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="payperview"
                                  onClick={(event) =>
                                    setActiveSection(event, "payperview")
                                  }
                                >
                                  <span>
                                    <Image
                                      className="profile-post-tab-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/my-profile/view-icon.svg"
                                      }
                                    />
                                  </span>
                                  <span className="resp-display-none">
                                    {t("pay_per_view")}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                            )}
                          </Nav>
                        </Col>
                        {activeSec != "payperview" ? (
                          <AllPost
                            activeSec={activeSec}
                            userPosts={props.posts}
                            fetchMoreData={fetchMorePost}
                            userPostAlbum={props.userPostAlbum}
                            fetchMorePostAlbum={fetchMorePostAlbum}
                            isOwner={true}
                          />
                        ) : (
                          <Col sm={12}>
                            <PayPerView />
                          </Col>
                        )}
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  posts: state.post.posts,
  userPostAlbum: state.postAlbum.postAlbum,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ProfileIndex));
