import React, { useState } from 'react';
import { Col, Row, Image, Button } from 'react-bootstrap'
import InfiniteScroll from "react-infinite-scroll-component";
import HomeLoader from '../../Loader/HomeLoader';
import NewFeedDisplayCard from '../../NewHome/NewFeedDisplayCard';
import NoDataFound from '../../NoDataFound/NoDataFound';
import { t } from 'react-multi-lang';
import { useHistory } from 'react-router-dom';
import PostAlbumPaymentModal from "../../Model/PaymentModal/PostAlbumPaymentModal";

const AllPost = (props) => {
  const history = useHistory();
  const { isOwner = false, isProfile = false, userDetails = null, subscriptionPayment = () => { } } = props;

  const [albumPayment, setAlbumPayment] = useState(null);

  const closePaymentModal = () => {
    setAlbumPayment(null);
  }

  return (
    <>
      <Col md={8}>
        {props.userPosts.loading ?
          <HomeLoader />
          : props.userPosts.data.posts.length > 0 ?
            <InfiniteScroll
              dataLength={props.userPosts.data.posts.length}
              next={props.fetchMoreData}
              hasMore={
                props.userPosts.data.posts.length <
                props.userPosts.data.total
              }
              loader={<HomeLoader />}
              style={{ height: 'auto', overflow: 'hidden' }}
            >
              <div className="new-feed-sec">
                {props.userPosts.data.posts.map((post, index) =>
                  <NewFeedDisplayCard
                    post={post}
                    key={index}
                    index={index}
                    isProfile={isProfile}
                    userDetails={userDetails}
                    subscriptionPayment={subscriptionPayment}
                  />
                )}
              </div>
            </InfiniteScroll>
            : <NoDataFound className = "no-data-found-img-sec-1 mt-5" />
        }
      </Col>
      {props.activeSec === "all" ?
        <Col md={4}>
          <div className="all-post-ppv-header-sec">
            <h3>{t('pay_per_view')}</h3>
          </div>
          <div className="all-post-ppv-sec payper-view-user-box">
            {props.userPostAlbum.loading ?
              "Loading"
              : props.userPostAlbum.data.post_albums &&
                props.userPostAlbum.data.post_albums.length > 0 ?
                props.userPostAlbum.data.post_albums.map((album, i) =>
                  <div className="payper-view-card" onClick={() => {
                    if (album.payment_info?.is_user_needs_pay == 1)
                      setAlbumPayment(album)
                    else
                      history.push(`/${isOwner ? "album" : "gallery"}/${album.user_unique_id}/${album.post_album_unique_id}`)
                  }}>
                    <div className="payper-view-img-sec">
                      <Image
                        src={album.thumbnail}
                        className="payper-view-img"
                      />
                      {album.payment_info?.is_user_needs_pay == 1 ?
                        <div className="open-unpaid-gallery-btn">
                          <span>{album.payment_info.payment_text}</span>
                        </div>
                        : <div className="open-paid-gallery-btn">
                          <span>{isOwner ? <>{t('posted_for')} {album.amount_formatted}</> : t('open_paid_gallery')}</span>
                        </div>
                      }
                    </div>
                    <div className="payper-view-count-info">
                      <div className="payper-view-count-card">
                        <Image
                          className="payper-view-count-icon"
                          src={
                            window.location.origin + "/assets/images/my-profile/amount.svg"
                          }
                        />
                        <span>{album.amount_formatted}</span>
                      </div>
                      <div className="payper-view-count-card">
                        <Image
                          className="payper-view-count-icon"
                          src={
                            window.location.origin + "/assets/images/my-profile/image-icon.svg"
                          }
                        />
                        <span>{album.total_images}</span>
                      </div>
                      <div className="payper-view-count-card">
                        <Image
                          className="payper-view-count-icon"
                          src={
                            window.location.origin + "/assets/images/my-profile/video-icon.svg"
                          }
                        />
                        <span>{album.total_videos}</span>
                      </div>
                    </div>
                    <div className="payper-view-info">
                      <h4>{album.name}</h4>
                      <p>{album.description}</p>
                    </div>
                  </div>
                ) : <NoDataFound />
            }
          </div>
          {props.userPostAlbum.data.post_albums.length < props.userPostAlbum.data.total ?
            <div className="load-more-btn-sec">
              <Button
                className="load-more-btn"
                onClick={() => props.fetchMorePostAlbum()}>
                {t("load_more")}
              </Button>
            </div> : null
          }
        </Col>
        : null
      }
      {albumPayment ?
        <PostAlbumPaymentModal
          paymentsModal={true}
          closepaymentsModal={closePaymentModal}
          albumData={albumPayment}
        /> : null
      }
    </>
  );
}

export default AllPost;