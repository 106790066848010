import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Media,
} from "react-bootstrap";
import "./LatestFooter.css";
import { Link } from "react-router-dom";
import ReactLanguageSelect from 'react-languages-select';
import 'react-languages-select/css/react-languages-select.css';
import configuration from "react-global-configuration";
import { setLanguage, } from "react-multi-lang";
import { translate, t } from "react-multi-lang";

const NewFooter = (props) => {

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("lang", lang);
    window.location.reload();
  }

  return (
    <>
      <div className="new-latest-footer-sec">
        <footer className="new-latest-footer-box">
          <Container>
            <Row className="">
              <Col md={4} xl={4} lg={4}>
                <div className="footer-about-info">
                  <Image
                    src={window.location.origin + "/assets/images/footer/logo.png"}
                    alt=""
                    className="footer-logo"
                  />
                </div>
              </Col>
              <Col md={2} xl={2} lg={2}>
                <div className="footer-widget-sec">
                  <h3>{t('about')}</h3>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/page/about">
                        {t('about_us')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/faq">
                        {t('faq')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/contact">
                        {t('contact_us')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={2} xl={2} lg={2}>
                <div className="footer-widget-sec">
                  <h3>{t('help')}</h3>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/page/terms">
                      {t('terms_conditions_footer')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/page/privacy">
                        {t('privacy_policy')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              {configuration.get("configData.facebook_link") ||
                configuration.get("configData.instagram_link") ?
                <Col md={2} xl={2} lg={2}>
                  <div className="footer-social-widget-sec">
                    <h3>{t('follow_us')}</h3>
                    <ul className="list-unstyled">
                      {configuration.get("configData.facebook_link") ?
                        <li>
                          <a href={configuration.get("configData.facebook_link")} target="_blank">
                            <Image
                              src={window.location.origin + "/assets/images/auth/fb-icon.svg"}
                              alt=""
                              className="footer-logo"
                            />
                          </a>
                        </li>
                        : null
                      }
                      {configuration.get("configData.instagram_link") ?
                        <li>
                          <a href={configuration.get("configData.instagram_link")} target="_blank">
                            <Image
                              src={window.location.origin + "/assets/images/auth/social-2.svg"}
                              alt=""
                              className="footer-logo"
                            />
                          </a>
                        </li>
                        : null
                      }
                    </ul>
                  </div>
                </Col>
                : null
              }
              <Col md={2} xl={2} lg={2}>
                {/* <div className="language-select-sec"> */}
                <div className="footer-widget-sec">
                  <h3>{t('language')}</h3>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="#" onClick={() => changeLanguage("en")}>
                        EN
                      </Link>
                    </li>
                    <li>
                      <Link to="#" onClick={() => changeLanguage("pl")}>
                        PL
                      </Link>
                    </li>
                  </ul>
                  {/* <ReactLanguageSelect
                    defaultLanguage="en"
                    languages={["en", "es"]}
                    searchPlaceholder="Search for a language"
                  /> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="footer-copyrights-sec">
                  <p>{configuration.get("configData.copyright_content")}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
};

export default translate(NewFooter);
