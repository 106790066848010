import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import AddNewFolderModal from "../NewProfile/AddNewFolderModal";
import FancyBox from "../../NewHome//NewSingleView/FancyBox";
import { fetchPostAlbumForOthersStart } from "../../../store/actions/PostAlbumAction";
import { connect } from 'react-redux';
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";
import PostAlbumPaymentModal from "../../Model/PaymentModal/PostAlbumPaymentModal";

const UserPayPerView = (props) => {
    const history = useHistory();
    const [albumPayment, setAlbumPayment] = useState(null);

    const [addNewFolder, setAddNewFolder] = useState(false);

    const closeAddNewFolderModal = () => {
        setAddNewFolder(false);
    };

    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(12);

    useEffect(() => {
        props.dispatch(fetchPostAlbumForOthersStart({ user_unique_id: props.otherUserUniquId, skip: 0, take: take }));
        setSkip(take);
    }, []);

    const fetchMorePostAlbums = () => {
        props.dispatch(
            fetchPostAlbumForOthersStart({
                user_unique_id: props.otherUserUniquId,
                append: true,
                skip: props.post_albums.data.post_albums.length,
                take: take,
            })
        );
        setSkip(skip + take);
    }

    const closePaymentModal = () => {
        setAlbumPayment(null);
    }

    return (
        <>

                        {props.post_albums.loading ?

                            <div className="payper-view-user-box">
                                {
                                    [...Array(8)].map(() =>
                                        <Skeleton
                                            className="profile-post-card-loader" />
                                    )
                                }
                            </div>
                            :
                            <>
                                <InfiniteScroll
                                    dataLength={props.post_albums.data.post_albums.length}
                                    next={fetchMorePostAlbums}
                                    hasMore={props.post_albums.data.post_albums.length < props.post_albums.data.total}
                                    loader={
                                        <div className="payper-view-user-box">
                                            {[...Array(4)].map(() =>
                                                <Skeleton
                                                    className="profile-post-card-loader" />
                                            )}
                                        </div>
                                    }
                                    style={{ height: 'auto', overflow: 'hidden' }}
                                >
                                    <div className="payper-view-user-box">

                                        {props.post_albums.data.post_albums.length > 0 ?
                                            props.post_albums.data.post_albums.map((post_album, index) =>
                                                <div className="payper-view-card" key={index}>
                                                    {post_album.payment_info.is_user_needs_pay == 0 ?
                                                        <div className="payper-view-img-sec"
                                                            onClick={() => history.push(`/gallery/${post_album.user_unique_id}/${post_album.post_album_unique_id}`)}>
                                                            <Image
                                                                className="payper-view-img"
                                                                src={post_album.thumbnail}
                                                            />
                                                            <div className="open-paid-gallery-btn">
                                                                <span>{t('open_paid_gallery')}</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div
                                                            className="payper-view-img-sec"
                                                            onClick={() => setAlbumPayment({
                                                                post_album_id: post_album.post_album_id,
                                                                amount_formatted: post_album.amount_formatted,
                                                                amount: post_album.amount,
                                                            })}
                                                        >
                                                            <Image
                                                                className="payper-view-img"
                                                                src={post_album.thumbnail}
                                                            />
                                                            <div className="open-unpaid-gallery-btn">
                                                                <span>{post_album.payment_info.payment_text}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="payper-view-count-info">
                                                        <div className="payper-view-count-card">
                                                            <Image
                                                                className="payper-view-count-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/my-profile/amount.svg"
                                                                }
                                                            />
                                                            <span>{post_album.amount_formatted}</span>
                                                        </div>
                                                        <div className="payper-view-count-card">
                                                            <Image
                                                                className="payper-view-count-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/my-profile/image-icon.svg"
                                                                }
                                                            />
                                                            <span>{post_album.total_images}</span>
                                                        </div>
                                                        <div className="payper-view-count-card">
                                                            <Image
                                                                className="payper-view-count-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/my-profile/video-icon.svg"
                                                                }
                                                            />
                                                            <span>{post_album.total_videos}</span>
                                                        </div>
                                                    </div>
                                                    <div className="payper-view-info">
                                                        <h4>{post_album.name}</h4>
                                                        <p>{post_album.description}</p>
                                                    </div>
                                                </div>
                                            ) : ''}
                                    </div>
                                </InfiniteScroll>

                            </>
                        }
            {
                albumPayment ?

                    <PostAlbumPaymentModal
                        paymentsModal={true}
                        closepaymentsModal={closePaymentModal}
                        user_unique_id={props.otherUserUniquId}
                        albumData={albumPayment}
                    />


                    : null
            }

        </>
    );
}

const mapStateToPros = (state) => ({
    post_albums: state.postAlbum.userPostAlbum,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(UserPayPerView));