import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import AddNewFolderModal from "../NewProfile/AddNewFolderModal";
import FancyBox from "../../NewHome//NewSingleView/FancyBox";
import { fetchPostAlbumForOwnerStart, deletePostAlbumStart } from "../../../store/actions/PostAlbumAction";
import { connect } from 'react-redux';
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useHistory } from "react-router-dom";

const PayPerView = (props) => {
  const history = useHistory();

  const [addNewFolder, setAddNewFolder] = useState(false);
  const [editPayPerView, setEditPayPerView] = useState(null);

  const closeAddNewFolderModal = () => {
    setEditPayPerView(null);
    setAddNewFolder(false);
  };

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(12);

  useEffect(() => {
    if (editPayPerView)
      setAddNewFolder(true);
  }, [editPayPerView]);

  useEffect(() => {
    props.dispatch(fetchPostAlbumForOwnerStart({ skip: 0, take: take }));
    setSkip(take);
  }, []);

  const fetchMorePostAlbums = () => {
    props.dispatch(
      fetchPostAlbumForOwnerStart({
        append: true,
        skip: props.post_albums.data.post_albums.length,
        take: take,
      })
    );
    setSkip(skip + take);
  }

  const deleteAlbum = postAlbumId => {
    props.dispatch(deletePostAlbumStart({ post_album_id: postAlbumId }));
  }

  return (
    <>
      <div className="profile-tab-sec">
        <Row>
          <Col md={12}>

            {props.post_albums.loading ?

              <div className="payper-view-box">
                {
                  [...Array(8)].map(() =>
                    <Skeleton
                      className="profile-post-card-loader" />
                  )
                }
              </div>
              :
              <>
                <InfiniteScroll
                  dataLength={props.post_albums.data.post_albums.length}
                  next={fetchMorePostAlbums}
                  hasMore={props.post_albums.data.post_albums.length < props.post_albums.data.total}
                  loader={
                    <div className="payper-view-user-box">
                      {[...Array(4)].map(() =>
                        <Skeleton
                          className="profile-post-card-loader" />
                      )}
                    </div>
                  }
                  style={{ height: 'auto', overflow: 'hidden' }}
                >
                  <div className="payper-view-box">
                    <div className="payper-view-add-folder-card" onClick={() => setAddNewFolder(true)}>
                      <Image
                        className="payper-view-add-bg-img"
                        src={
                          window.location.origin + "/assets/images/my-profile/shape-img.png"
                        }
                      />
                      <div className="payper-view-create-card">
                        <Image
                          className="my-profile-cc-add-new-icon"
                          src={
                            window.location.origin + "/assets/images/my-profile/add-content.svg"
                          }
                        />
                        <span>{t('create_new_folder')}</span>
                      </div>
                    </div>
                    {props.post_albums.data.post_albums.length > 0 ?
                      props.post_albums.data.post_albums.map((post_album) =>
                        <>
                          <div className="payper-view-card">
                            <div className="payper-view-img-sec"
                              onClick={() => history.push(`/album/${post_album.user_unique_id}/${post_album.post_album_unique_id}`)}>
                              <Image
                                className="payper-view-img"
                                src={post_album.thumbnail}
                              />
                              <div className="payper-view-action-section">
                                <div className="payper-view-action">
                                  <Image
                                    onClick={e => {
                                      setEditPayPerView(post_album);
                                      e.stopPropagation();
                                    }}
                                    src={
                                      window.location.origin + "/assets/images/new-home/icon/edit-profile-theme-1.svg"
                                    }
                                  />
                                </div>
                                <div className="payper-view-action">
                                  <Image
                                    onClick={e => {
                                      window.confirm(t("folder_delete_msg"))
                                        ? deleteAlbum(post_album.post_album_id)
                                        : e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    src={
                                      window.location.origin + "/assets/images/my-profile/delete-icon.svg"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="payper-view-amount">
                                {t('posted_for')} {post_album.amount_formatted}
                              </div>
                            </div>
                            <div className="payper-view-count-info">
                              <div className="payper-view-count-card">
                                <Image
                                  className="payper-view-count-icon"
                                  src={
                                    window.location.origin + "/assets/images/my-profile/amount.svg"
                                  }
                                />
                                <span>{post_album.amount_formatted}</span>
                              </div>
                              <div className="payper-view-count-card">
                                <Image
                                  className="payper-view-count-icon"
                                  src={
                                    window.location.origin + "/assets/images/my-profile/image-icon.svg"
                                  }
                                />
                                <span>{post_album.total_images}</span>
                              </div>
                              <div className="payper-view-count-card">
                                <Image
                                  className="payper-view-count-icon"
                                  src={
                                    window.location.origin + "/assets/images/my-profile/video-icon.svg"
                                  }
                                />
                                <span>{post_album.total_videos}</span>
                              </div>
                            </div>
                            <div className="payper-view-info">
                              <h4>{post_album.name}</h4>
                              <p>{post_album.description}</p>
                            </div>
                          </div>
                        </>
                      ) : ''}
                  </div>
                </InfiniteScroll>

              </>
            }
          </Col>
        </Row>
      </div>
      {addNewFolder ?
        <AddNewFolderModal
          addNewFolder={addNewFolder}
          selectedPayPerView={editPayPerView}
          closeAddNewFolderModal={closeAddNewFolderModal}
          setAddNewFolder={setAddNewFolder}
        />
        : null
      }
    </>
  );
}

const mapStateToPros = (state) => ({
  post_albums: state.postAlbum.postAlbum,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PayPerView));