import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Table,
  Badge,
} from "react-bootstrap";
import "./BecomeAContentCreator.css";
import { connect } from "react-redux";
import {
  deleteBankAccountContentCreatorFlowStart,
  getBankAccountStart,
  makeDefaultBankAccountStart,
  addBankAccountContentreatorFlowStart,
} from "../../store/actions/BankAccountAction";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import NoDataFound from "../NoDataFound/NoDataFound";
import BillingAccountLoader from "../Loader/BillingAccountLoader";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Step4 = (props) => {
  const [inputData, setInputData] = useState({
    bank_type: "savings",
  });
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);

  useEffect(() => {
    if (props.user.data.content_creator_step === 0) {
      const notificationMessage = getErrorNotificationMessage(
        t("upload_doc_message")
      );
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(0);
    } else if (props.user.data.content_creator_step === 1) {
      const notificationMessage = getErrorNotificationMessage(
        t("doc_verification_pending_message")
      );
      props.dispatch(fetchUserDetailsStart());
      props.dispatch(createNotification(notificationMessage));
      props.jumpToStep(2);
    }
    props.dispatch(getBankAccountStart());
  }, []);

  const handleSubmit = (values) => {
    // event.preventDefault();
    props.dispatch(addBankAccountContentreatorFlowStart(values));
  };

  useEffect(() => {
    if (
      props.addBankAccount.data.success &&
      props.user.data.content_creator_step === 2
    ) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, [props.addBankAccount.data]);

  const validationSchema = Yup.object().shape({
    route_number: Yup.string().required(t("route_number_required")),
    account_number: Yup.number()
      .required(t("account_number_required"))
      .min(0, t("invalid_account_number")),
    first_name: Yup.string().required(t("first_name_is_required")),
    last_name: Yup.string().required(t("last_name_is_required")),
    bank_type: Yup.string().required(t("bank_type_is_required")),
    business_name: Yup.string(),
    country: Yup.string().required(t("country_is_required")),
    zipcode: Yup.string().required(t("zipcode_is_required")),
    building_no: Yup.string().required(t("building_no_is_required")),
    city: Yup.string().required(t("city_is_required")),
  });

  return (
    <>
      <div className="step-5-payout-method-sec">
        <div className="step-5-info">
          <h4>{t("payout_methods")}</h4>
          <p>
            {t("payout_methods_description")}
            {/* {t('there_are_two_different_payment_types_available_to_you_below')} */}
          </p>
        </div>
        {props.bankAccount.loading ? (
          <BillingAccountLoader />
        ) : props.bankAccount.data.billing_accounts.length > 0 ? (
          <Row>
            <Col sm={12} md={12}>
              <div className="trans-table">
                <Table borderedless responsive>
                  <thead>
                    <tr className="bg-white text-muted text-center">
                      <th>{t("name")}</th>
                      <th>{t("surname")}</th>
                      <th>{t("route_number")}</th>
                      <th>{t("account_number")}</th>
                      {/* <th>{t("bank_type")}</th>
                      <th>{t("business_name")}</th> */}
                      <th>{t("is_default")}</th>
                      <th>{t("status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.bankAccount.data.billing_accounts.map((accounts) => (
                      <tr key={accounts.user_billing_account_id}>
                        <td className="text-capitalize">
                          {accounts.first_name ? accounts.first_name : "-"}
                        </td>
                        <td className="text-capitalize">
                          {accounts.last_name ? accounts.last_name : "-"}
                        </td>
                        <td>
                          {accounts.route_number ? accounts.route_number : "-"}
                        </td>
                        <td className="amount">
                          {accounts.account_number
                            ? accounts.account_number
                            : "-"}
                        </td>
                        <td className="text-capitalize">
                          {accounts.bank_type ? accounts.bank_type : "-"}
                        </td>
                        <td className="text-capitalize">
                          {accounts.business_name
                            ? accounts.business_name
                            : "-"}
                        </td>
                        {accounts.is_default === 1 ? (
                          <td>
                            <Badge className="confirmed-badge">
                              {t("yes")}
                            </Badge>
                          </td>
                        ) : (
                          <td>
                            <Badge className="unconfirmed-badge">
                              {t("no")}
                            </Badge>
                          </td>
                        )}
                        <td>
                          {accounts.is_default === 0 ? (
                            <Button
                              variant="success"
                              onClick={() =>
                                props.dispatch(
                                  makeDefaultBankAccountStart({
                                    user_billing_account_id:
                                      accounts.user_billing_account_id,
                                  })
                                )
                              }
                            >
                              {t("make_default")}
                            </Button>
                          ) : null}{" "}
                          <Button
                            variant="danger"
                            onClick={() => {
                              if (
                                window.confirm(
                                  t("delete_billing_acc_confirmation")
                                )
                              ) {
                                props.dispatch(
                                  deleteBankAccountContentCreatorFlowStart({
                                    user_billing_account_id:
                                      accounts.user_billing_account_id,
                                  })
                                );
                              }
                            }}
                          >
                            {t("delete")}
                          </Button>{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        ) : null}
        <div className="step-5-payment-method">
          {/* <h5>Bank Transfer</h5> */}
          <Formik
            initialValues={{
              route_number: "",
              account_number: "",
              first_name: "",
              last_name: "",
              bank_type: "savings",
              building_no: "",
              city: "",
              country: "",
              zipcode: "",
              business_name: "",
              acceptTerms: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ touched, errors, isSubmitting, setFieldValue, values }) => (
              <Form noValidate>
                {/* <Form onSubmit={handleSubmit}> */}
                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">
                        {t("routing_number")}: (*)
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("routing_number")}
                        name="route_number"
                        value={values.route_number}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="route_number"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">
                        {t("account_number")}: (*)
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder={t("account_number")}
                        name="account_number"
                        value={values.account_number}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_number"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">{t("name")}: (*)</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder={t("name")}
                        name="first_name"
                        value={values.first_name}
                        autoFocus={true}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="first_name"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">{t("surname")}: (*)</label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder={t("surname")}
                        name="last_name"
                        value={values.last_name}
                        autoFocus={true}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="last_name"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">{t("country")}:(*)</label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("country")}
                        name="country"
                        value={values.country}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="country"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">
                        {t("street_details")}:
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("street_details")}
                        name="street_details"
                        value={values.street_details}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="street_details"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">{t("flat_no")}:</label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("flat_no")}
                        name="flat_no"
                        value={values.flat_no}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="flat_no"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">
                        {t("building_no")}:(*)
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("building_no")}
                        name="building_no"
                        value={values.building_no}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="building_no"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">{t("city")}:(*)</label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("city")}
                        name="city"
                        value={values.city}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="city"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label">{t("zipcode")}:(*)</label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder={t("zipcode")}
                        name="zipcode"
                        value={values.zipcode}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="zipcode"
                        className="error-msg text-danger text-right"
                      />
                    </div>
                  </Col>

                  {/* <Col md={6}>
                <Form.Group controlId="formHorizontalLastname">
                  <Form.Label>{t("last_name")}: (*)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("last_name")}
                    value={inputData.last_name}
                    name="last_name"
                    onChange={(event) => {
                      setInputData({
                        ...inputData,
                        last_name: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col> */}
                  {/* <Col md={6}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>
                    {t("type_of_bank")}{" "}
                    <span className="text-capitalize">(*)</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="bank_type"
                    onChange={(event) => {
                      setInputData({
                        ...inputData,
                        bank_type: event.currentTarget.value,
                      });
                    }}
                  >
                    <option value="savings">{t("savings")}</option>
                    <option value="checking">{t("checking")}</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formHorizontalBusinessname">
                  <Form.Label>
                    {t("business_name")}: ({t("optional")})
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("business_name")}
                    value={inputData.business_name}
                    name="business_name"
                    onChange={(event) => {
                      setInputData({
                        ...inputData,
                        business_name: event.currentTarget.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col> */}

                  <Col md={12}>
                    <input
                      checked={isCheckboxEnabled}
                      type="checkbox"
                      id="acceptterms"
                      name="acceptTerms"
                      onChange={() => setIsCheckboxEnabled(!isCheckboxEnabled)}
                    />

                    <label className="form-label">
                      {t("i_agree_to")}
                      <Link
                        target="_blank"
                        to="/page/terms"
                        className="terms-link"
                      >
                        {" "}
                        {t("terms_conditions")}{" "}
                      </Link>
                    </label>
                    <ErrorMessage
                      component={"div"}
                      name="acceptTerms"
                      className="error-msg text-danger"
                    />
                  </Col>
                </Row>

                <div className="edit-save">
                  <Button
                    className="btn add-accout-btn"
                    type="submit"
                    disabled={
                      props.addBankAccount.buttonDisable || !isCheckboxEnabled
                    }
                  >
                    {props.addBankAccount.loadingButtonContent !== null
                      ? props.addBankAccount.loadingButtonContent
                      : t("add_account")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.bankAccount,
  addBankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step4));
