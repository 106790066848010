import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./AddBankIndex.css";
import { addBankAccountStart } from "../../../store/actions/BankAccountAction";
import { translate, t } from "react-multi-lang";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddBankIndex = (props) => {
  const [inputData, setInputData] = useState({});

  const validationSchema = Yup.object().shape({
    route_number: Yup.string().required(t("route_number_required")),
    account_number: Yup.number()
      .required(t("account_number_required"))
      .min(0, t("invalid_account_number")),
    first_name: Yup.string().required(t("first_name_is_required")),
    last_name: Yup.string().required(t("last_name_is_required")),
    bank_type: Yup.string().required(t("bank_type_is_required")),
    business_name: Yup.string(),
    country: Yup.string().required(t("country_is_required")),
    zipcode: Yup.string().required(t("zipcode_is_required")),
    building_no: Yup.string().required(t("building_no_is_required")),
    city: Yup.string().required(t("city_is_required")),
    acceptTerms: Yup.bool().oneOf(
      [true],
      t("accept_terms_conditions_is_required")
    ),
  });

  const handleSubmit = (values) => {
    // event.preventDefault();
    props.dispatch(addBankAccountStart(values));
  };

  return (
    <div className="card-list-sec">
      <Container>
        {/* <Link
          className="bookmarkes-list notify-title back-button head-title"
          onClick={() =>
            props.location.state && props.location.state.prevPath
              ? props.history.goBack()
              : props.history.push("/home")
          }
        >
          <img
            src={window.location.origin + "/assets/images/icons/back.svg"}
            className="svg-clone"
          /> */}
        {/* <Link>{t("add_bank")} </Link> */}
        {/* </Link> */}
        {/* <h4 className="bookmarkes-list notify-title back-button head-title">
          {t("add_bank")}
        </h4> */}
        <div className="my-profile-new-header pt-0">
          <h1>{t("add_bank")}</h1>
        </div>
        <Row>
          <Col sm={12} md={12}>
            <div className="add-bank-box">
              <Formik
                initialValues={{
                  route_number: "",
                  account_number: "",
                  first_name: "",
                  last_name: "",
                  bank_type: "savings",
                  building_no: "",
                  city: "",
                  country: "",
                  zipcode: "",
                  business_name: "",
                  acceptTerms: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                  <Form noValidate>
                    <Row>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">{t("name")}: (*)</label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("name")}
                            name="first_name"
                            value={values.first_name}
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="first_name"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("surname")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("surname")}
                            name="last_name"
                            value={values.last_name}
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="last_name"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("routing_number")}: (*)
                          </label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("routing_number")}
                            name="route_number"
                            value={values.route_number}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="route_number"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("account_number")}: (*)
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("account_number")}
                            name="account_number"
                            value={values.account_number}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="account_number"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("street_details")}:
                          </label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("street_details")}
                            name="street_details"
                            value={values.street_details}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="street_details"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">{t("flat_no")}:</label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("flat_no")}
                            name="flat_no"
                            value={values.flat_no}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="flat_no"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("building_no")}:(*)
                          </label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("building_no")}
                            name="building_no"
                            value={values.building_no}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="building_no"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">{t("city")}:(*)</label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("city")}
                            name="city"
                            value={values.city}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="city"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("zipcode")}:(*)
                          </label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("zipcode")}
                            name="zipcode"
                            value={values.zipcode}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="zipcode"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <label className="form-label">
                            {t("country")}:(*)
                          </label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("country")}
                            name="country"
                            value={values.country}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="country"
                            className="error-msg text-danger text-right"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Col md={6}>
                      <div class="check-terms custom-control custom-checkbox custom-control-inline">
                        <Field
                          type="checkbox"
                          id="acceptterms"
                          name="acceptTerms"
                          custom
                          required={true}
                          className="custom-control-input"
                        />
                        <label
                          title=""
                          for="acceptterms"
                          class="custom-control-label"
                        ></label>
                      </div>
                      <label className="form-label">
                        {t("i_agree_to")}
                        <Link
                          target="_blank"
                          to="/page/terms"
                          className="terms-link"
                        >
                          {" "}
                          {t("terms_conditions")}{" "}
                        </Link>
                      </label>
                      <ErrorMessage
                        component={"div"}
                        name="acceptTerms"
                        className="error-msg text-danger text-right"
                      />
                    </Col>

                    <div className="edit-save">
                      <Button
                        className="btn gradient-btn gradientcolor addBank"
                        type="submit"
                        disabled={props.bankAccount.buttonDisable}
                      >
                        {props.bankAccount.loadingButtonContent !== null
                          ? props.bankAccount.loadingButtonContent
                          : t("submit")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  bankAccount: state.bankAccount.addBankAccountInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(AddBankIndex));
