import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Button, Form, Image, Tab, Nav, Media } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchPostsStart } from "../../../store/actions/PostAction";
import {
  fetchSingleUserProfileStart,
} from "../../../store/actions/OtherUserAction";
import { connect } from 'react-redux';
import { translate, t } from "react-multi-lang";
import 'react-loading-skeleton/dist/skeleton.css';
import ProfileLoader from "../../Loader/ProfileLoader";
import ProfileSidebar from "../Profile/ProfileSidebar";
import './MyProfile.css';
import PostAlbumFiles from "./PostAlbumFiles";
import OtherProfileSidebar from '../Profile/OtherProfileSidebar';

const PostGalleryIndex = (props) => {

  useEffect(() => {
    if (props.userDetails.loading) {
      props.dispatch(
        fetchSingleUserProfileStart({
          user_unique_id: props.match.params.user_unique_id,
        })
      );
    }
  }, []);

  return (
    <>
      <div className="new-home-sec">
        <Container>
          {props.userDetails.loading ? (
            <ProfileLoader />
          )
            :
            <div>
              <div className="my-profile-new-header">
                {/* <Link
                  to={"#"}
                  onClick={() => props.history.goBack()}
                >
                  <Image
                    src={
                      window.location.origin +
                      "/assets/images/my-profile/back-arrow-btn.svg"
                    }
                    className="svg-clone"
                  />

                </Link> */}
                <h1>{t('gallery_files')}</h1>

              </div>
              <div className="new-home-box">
                <OtherProfileSidebar userDetails={props.userDetails} />
                <div className="new-home-main-wrapper">
                  <PostAlbumFiles post_album_unique_id={props.match.params.post_album_unique_id} />
                </div>
              </div>
            </div>
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  userDetails: state.otherUser.userDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PostGalleryIndex));
