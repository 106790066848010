import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Table, Badge } from "react-bootstrap";
import "../../Wallet/Wallet.css";
import {
    fetchBlockRequestStart,
    deleteBlockRequestStart,
} from "../../../store/actions/UserAction";
import { Link } from "react-router-dom";
import NoDataFound from "../../NoDataFound/NoDataFound";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";

const BlockRequestIndex = (props) => {
    useEffect(() => {
        props.dispatch(fetchBlockRequestStart());
    }, []);
    return (
        <>
            <div className="wallet-sec">
                <Container>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="wallet-header-sec pt-0">
                                <Row>
                                    <Col sm={12} md={12} xl={12}>
                                        {/* <Link
                                            className="bookmarkes-list notify-title back-button"
                                            onClick={() => props.history.goBack()}
                                        >
                                            <img
                                                src={
                                                    window.location.origin +
                                                    "/assets/images/icons/back.svg"
                                                }
                                                className="svg-clone"
                                            />
                                            <h3 className="ml-2 mb-0">{t("block_requests")}</h3>
                                        </Link> */}

                                        <div className="my-profile-new-header pt-0">
                                            <h1>{t("block_requests")}</h1>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="trans-table-sec">
                <Container>
                    {props.blockRequest.loading ? (
                        <BillingAccountLoader />
                    ) : props.blockRequest.data.block_user_requests.length > 0 ? (
                        <Row>
                            <Col sm={12} md={12}>
                                <div className="trans-table">
                                    <Table borderedless responsive>
                                        <thead>
                                            <tr className="bg-white text-muted text-center text-nowrap">
                                                <th>{t("username")}</th>
                                                <th>{t("status")}</th>
                                                <th>{t("action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.blockRequest.data.block_user_requests.map(
                                                (requests) => (
                                                    <tr key={requests.block_user_request_id} className="justify-content-center">
                                                        <td className="text-capitalize">
                                                            {requests.user_displayname}
                                                        </td>
                                                        <td className="text-capitalize">
                                                            {requests.status_formatted}
                                                        </td>

                                                        <td>
                                                            <div className="row">

                                                                <Button
                                                                    variant="danger"
                                                                    className="col-12"
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                t("delete_request_confirmation")
                                                                            )
                                                                        ) {
                                                                            props.dispatch(
                                                                                deleteBlockRequestStart({
                                                                                    block_user_request_id:
                                                                                    requests.block_user_request_id,
                                                                                })
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {t("delete")}
                                                                </Button>{" "}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <NoDataFound />
                    )}
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    blockRequest: state.users.blockRequest,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(BlockRequestIndex));
