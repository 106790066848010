import React, { useState, useEffect } from "react";
import { Image, Tab, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchChatAssetMediaFilesStart, fetchMoreChatAssetMediaFilesStart } from "../../store/actions/ChatAction";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../NoDataFound/NoDataFound";
import ChatAssetPaymentModal from "../Model/PaymentModal/ChatAssetPaymentModal";
import ReactFancyBox from "react-fancybox";
import ReactAudioPlayer from "react-audio-player";
import AltraAudioPlayer from "../Custom/AudioPlayer/AltraAudioPlayer";

const ChatMedia = (props) => {

  const [fileType, setFileType] = useState("image");
  const fromUserId = localStorage.getItem("userId");
  const [chatPayment, setChatPayment] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState(null);

  const [paymentData, setPaymentData] = useState({
    chat_message_id: 0,
    from_user_id: fromUserId,
    to_user_id: props.toUserId,
    amount_formatted: 0,
    amount: 0,
  });

  const closeAudioPlayer = () => {
    setAudioPlayer(null);
  }

  useEffect(() => {
    props.dispatch(fetchChatAssetMediaFilesStart({
      file_type: fileType,
      to_user_id: props.toUserId,
      from_user_id: fromUserId,
      skip: 0,
      take: 12,
    }));
  }, [fileType]);

  const fetchMoreData = () => {
    props.dispatch(fetchMoreChatAssetMediaFilesStart({
      file_type: fileType,
      to_user_id: props.toUserId,
      from_user_id: fromUserId,
      skip: props.chatAssetMedias.data.chat_assets.length,
      take: 12,
    }))
  }


  const onPayment = (chat_message_id, amount, amount_formatted) => {
    setPaymentData({
      ...paymentData,
      chat_message_id: chat_message_id,
      amount: amount,
      amount_formatted: amount_formatted,
    });
    setChatPayment(true);
  }

  const closePaymentModal = (response = null) => {
    setChatPayment(false);
    if (response) {
      props.dispatch(fetchMoreChatAssetMediaFilesStart({
        file_type: fileType,
        to_user_id: props.toUserId,
        from_user_id: fromUserId,
        skip: 0,
        take: props.chatAssetMedias.data.chat_assets.length,
      }))
    }
  };

  const audioFancyBox = (src) => {

  }

  return (
    <div>
      <div className="">
        <div className="chat-media-tab">
          <div className="media-back-btn" onClick={() => props.setStep(1)}>
            <Image
              src={window.location.origin + "/assets/images/icons/back.svg"}
            />
          </div>
          <Tab.Container id="left-tabs-example" activeKey={fileType} defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="image" onClick={() => setFileType("image")}>{t('image')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="video" onClick={() => setFileType("video")}>{t('video')}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="audio" onClick={() => setFileType("audio")}>{t('audio')}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                {props.chatAssetMedias.loading
                  ? <div className="chat-media-gallery">
                    {[...Array(8)].map((num, i) =>
                      <div className="new-chat-user-media-img-sec" key={i}>
                        <Skeleton className="new-chat-user-media-img" />
                      </div>
                    )}
                  </div>
                  : props.chatAssetMedias.data.chat_assets &&
                    props.chatAssetMedias.data.chat_assets.length > 0 ?
                    <InfiniteScroll
                      dataLength={props.chatAssetMedias.data.chat_assets.length}
                      next={fetchMoreData}
                      hasMore={
                        props.chatAssetMedias.data.chat_assets.length <
                        props.chatAssetMedias.data.total
                      }
                      loader={<div className="chat-media-gallery">
                        {[...Array(8)].map((num, i) =>
                          <div className="new-chat-user-media-img-sec" key={i}>
                            <Skeleton className="new-chat-user-media-img" />
                          </div>
                        )}
                      </div>
                      }
                    >
                      <div className="new-chat-media-sec">
                        <div className="chat-media-gallery">
                          {props.chatAssetMedias.data.chat_assets.map((file, i) =>
                            <Link to="#" key={i}>
                              {file.file_type === "image" ?
                                file.amount == 0 || file.is_paid || file.from_user_id == fromUserId ?
                                  // paid
                                  <div
                                    className="new-chat-user-media-card"
                                    data-fancybox="gallery"
                                    href={file.file}>
                                    <div className="new-chat-user-media-img-sec">
                                      <Image
                                        className="new-chat-user-media-img"
                                        src={file.file}
                                      />
                                    </div>
                                  </div>
                                  : // Non paid
                                  <div
                                    className="new-chat-user-media-card"
                                    onClick={() => onPayment(
                                      file.chat_message_id,
                                      file.amount,
                                      file.amount_formatted)}
                                  >
                                    <div className="new-chat-user-media-img-sec">
                                      <Image
                                        className="new-chat-user-media-img"
                                        src={file.blur_file}
                                        data-fancybox
                                      />
                                      <div className="new-chat-user-media-video-icon-sec">
                                        <Image
                                          src={
                                            window.location.origin +
                                            "/assets/images/new-home/icon/lock-icon.png"
                                          }
                                          className="new-chat-user-media-video-icon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                : file.file_type === "video" ?
                                  file.amount == 0 || file.is_paid || file.from_user_id == fromUserId ?
                                    //Paid
                                    <div
                                      className="new-chat-user-media-card"
                                      data-fancybox="gallery"
                                      href={file.file}
                                    >
                                      <div className="new-chat-user-media-img-sec">
                                        <Image
                                          className="new-chat-user-media-img"
                                          src={file.blur_file}
                                        />
                                        <div className="new-chat-user-media-video-icon-sec">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/images/new-home/icon/video-icon.png"
                                            }
                                            className="new-chat-user-media-video-icon"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    : //Non paid
                                    <div className="new-chat-user-media-card"
                                      onClick={() => onPayment(
                                        file.chat_message_id,
                                        file.amount,
                                        file.amount_formatted)}
                                    >
                                      <div className="new-chat-user-media-img-sec">
                                        <Image
                                          className="new-chat-user-media-img"
                                          src={file.blur_file}
                                          data-fancybox
                                        />
                                        <div className="new-chat-user-media-video-icon-sec">
                                          <Image
                                            src={
                                              window.location.origin +
                                              "/assets/images/new-home/icon/lock-icon.png"
                                            }
                                            className="new-chat-user-media-video-icon"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  : file.file_type === "audio" ?
                                    file.amount == 0 || file.is_paid || file.from_user_id == fromUserId ?
                                      //Paid
                                      <div
                                        className="new-chat-user-media-card"
                                        onClick={() => setAudioPlayer(i)}
                                      >
                                        <div className="new-chat-user-media-img-sec">
                                          <Image
                                            className="new-chat-user-media-img"
                                            src={file.blur_file}
                                            data-fancybox
                                          />
                                          <div className="new-chat-user-media-video-icon-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/images/new-home/icon/audio-icon.png"
                                              }
                                              className="new-chat-user-media-video-icon"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      // <div className="user-message-info">
                                      //   <ReactAudioPlayer
                                      //     src={
                                      //       file.file
                                      //     }
                                      //     controls={true}
                                      //     width="450px"
                                      //     height="450px"
                                      //     className="chat-room-audio-display"
                                      //     autoPlay={false}
                                      //     controlsList={"nodownload"}
                                      //   />
                                      // </div>

                                      : //Non paid
                                      <div className="new-chat-user-media-card"
                                        onClick={() => onPayment(
                                          file.chat_message_id,
                                          file.amount,
                                          file.amount_formatted)}
                                      >
                                        <div className="new-chat-user-media-img-sec">
                                          <Image
                                            className="new-chat-user-media-img"
                                            src={file.blur_file}
                                            data-fancybox
                                          />
                                          <div className="new-chat-user-media-video-icon-sec">
                                            <Image
                                              src={
                                                window.location.origin +
                                                "/assets/images/new-home/icon/lock-icon.png"
                                              }
                                              className="new-chat-user-media-video-icon"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    : null
                              }
                            </Link>
                          )}
                        </div>
                      </div>
                    </InfiniteScroll>
                    : <NoDataFound />
                }
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {chatPayment ?
        <ChatAssetPaymentModal
          paymentsModal={chatPayment}
          closepaymentsModal={closePaymentModal}
          paymentData={paymentData}
        />
        : null
      }
      {audioPlayer !== null ?
        <AltraAudioPlayer
          src={props.chatAssetMedias.data.chat_assets.filter(file => file.amount == 0 || file.is_paid || file.from_user_id == fromUserId)}
          srcKey="file"
          initialIndex={audioPlayer}
          imageKey="blur_file"
          listLoop={true}
          closeAudioPlayer={closeAudioPlayer}
        />
        : null
      }
    </div>
  );
};

const mapStateToPros = (state) => ({
  chatAssetMedias: state.chat.chatAssetMedias,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ChatMedia));
