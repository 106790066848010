import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  Tab,
  Nav,
  Media,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NewProfile.css";
import { fetchPostsStart } from "../../../store/actions/PostAction";
import {
  fetchSingleUserProfileStart,
  fetchSingleUserPostsStart,
} from "../../../store/actions/OtherUserAction";
import { connect } from "react-redux";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { translate, t } from "react-multi-lang";
import "./NewProfile.css";
import ProfileSinglePost from "../../helper/ProfileSinglePost";
import { saveChatUserStart } from "../../../store/actions/ChatAction";
import SendTipModal from "../../helper/SendTipModal";
import PaymentModal from "../../helper/PaymentModal";
import PrivateCallModal from "../../helper/PrivateCallModal";
import PrivateAudioCallModal from "../../helper/PrivateAudioCallModal";
import { subscriptionPaymentStripeStart } from "../../../store/actions/SubscriptionAction";
import { unFollowUserStart } from "../../../store/actions/FollowAction";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../NoDataFound/NoDataFound";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProfileLoader from "../../Loader/ProfileLoader";
import { saveBlockUserStart } from "../../../store/actions/UserAction";
import ModelProfileStoreSec from "../../Model/ModelProfileStoreSec";
import SendTipPaymentModal from "../../Model/PaymentModal/SendTipPaymentModal";
import SubscriptionPaymentModal from "../../Model/PaymentModal/SubscriptionPaymentModal";
import UserPayPerView from "./UserPayPerView";
import OtherProfileSidebar from "./OtherProfileSidebar";
import AllPost from "./AllPost";
import { fetchPostAlbumForOthersStart } from "../../../store/actions/PostAlbumAction";

const SingleProfile = (props) => {
  const [badgeStatus, setBadgeStatus] = useState(0);

  const [activeSec, setActiveSec] = useState("all");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [requestVideoCall, setRequestVideoCall] = useState(false);
  const [requestAudioCall, setRequestAudioCall] = useState(false);
  const [sendTip, setSendTip] = useState(false);
  const [subscrptionPayment, setPaymentModal] = useState(false);
  const [showUnfollow, setShowUnfollow] = useState(false);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(12);

  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });

  const toggleVisibility = () => {};

  useEffect(() => {
    props.dispatch(
      fetchSingleUserProfileStart({
        user_unique_id: props.match.params.username,
      })
    );
    props.dispatch(
      fetchSingleUserPostsStart({
        user_unique_id: props.match.params.username,
        type: "all",
        skip: 0,
        take: take,
      })
    );
    props.dispatch(
      fetchPostAlbumForOthersStart({
        user_unique_id: props.match.params.username,
        skip: 0,
        take: 12,
      })
    );
    setSkip(take);

    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const setActiveSection = (event, key) => {
    setActiveSec(key);
    if (key !== "product" && key !== "payperview") {
      props.dispatch(
        fetchSingleUserPostsStart({
          type: key,
          user_unique_id: props.match.params.username,
          skip: 0,
          take: take,
        })
      );
      if (key === "all")
        props.dispatch(
          fetchPostAlbumForOthersStart({
            user_unique_id: props.match.params.username,
            skip: 0,
            take: 12,
          })
        );
      setSkip(take);
    }
  };

  const fetchMorePost = () => {
    props.dispatch(
      fetchSingleUserPostsStart({
        type: activeSec,
        user_unique_id: props.match.params.username,
        append: true,
        skip: skip,
        take: take,
      })
    );
    setSkip(skip + take);
  };

  const fetchMorePostAlbum = () => {
    props.dispatch(
      fetchPostAlbumForOthersStart({
        user_unique_id: props.match.params.username,
        append: true,
        skip: props.userPostAlbum.data.post_albums.length,
        take: 12,
      })
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setPaymentModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const handleBlockUser = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      saveBlockUserStart({
        user_id: user_id,
        is_other_profile: 1,
      })
    );
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const { userDetails } = props;

  const closePrivateCallModal = () => {
    setRequestVideoCall(false);
    setRequestAudioCall(false);
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="new-home-sec">
        <Container>
          {userDetails.loading ? (
            <ProfileLoader />
          ) : (
            <div>
              <div className="my-profile-new-header">
                {/* <Link
                to={"#"}
                onClick={() => props.history.goBack()}
              >
                <Image
                  src={
                    window.location.origin +
                    "/assets/images/my-profile/back-arrow-btn.svg"
                  }
                  className="svg-clone"
                />

              </Link> */}
                <h1>{userDetails.data.user.username}</h1>
              </div>
              <div className="new-home-box">
                <OtherProfileSidebar userDetails={userDetails} />

                <div className="new-home-main-wrapper">
                  <div className="user-cover-img-sec">
                    <Image
                      className="user-cover-img"
                      src={userDetails.data.user.cover}
                      alt={userDetails.data.user.name}
                    />
                    <div className="website-hide-sec">
                      {userDetails.data.user.featured_story ? (
                        <a
                          data-fancybox="gallery"
                          href={userDetails.data.user.featured_story}
                        >
                          <Image
                            src={userDetails.data.user.picture}
                            alt={userDetails.data.user.name}
                            className="single-profile-user-img border-red"
                          />
                        </a>
                      ) : (
                        <Image
                          src={userDetails.data.user.picture}
                          alt={userDetails.data.user.name}
                          className="single-profile-user-img"
                        />
                      )}
                    </div>
                  </div>
                  <div className="user-right-content-sec">
                    <div className="user-right-info">
                      <div className="website-hide-sec">
                        <div className="mobile-header-sec">
                          <h4>
                            {userDetails.data.user.name}
                            <span>
                              {userDetails.data.user.is_verified_badge == 1 && (
                                <Image
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.png"
                                  }
                                />
                              )}
                            </span>
                          </h4>
                          {/* <Link to="#" className="sidebar-user-name">
                            {userDetails.data.user.email}
                          </Link> */}
                          <div className="sidebar-total-count-info-box">
                            <div className="sidebar-total-count-card">
                              <h5>{userDetails.data.user.total_posts}</h5>
                              <p>{t("posts")}</p>
                            </div>
                            <div className="sidebar-total-count-card">
                              <h5>{userDetails.data.user.total_followers}</h5>
                              <p>{t("fans")}</p>
                            </div>
                            <div className="sidebar-total-count-card">
                              <h5>{userDetails.data.user.total_followings}</h5>
                              <p>{t("following")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {userDetails.data.user.about_formatted ? (
                        <>
                          <h3>{t("about_me")}</h3>
                          <div className="user-info-desc">
                            <p>{userDetails.data.user.about_formatted}</p>
                          </div>
                        </>
                      ) : null}
                      <div className="user-info-list">
                        <ul className="list-unstyled">
                          {userDetails.data.user.selected_category &&
                            userDetails.data.user.selected_category.name && (
                              <Media as="li">
                                <Link to="#">
                                  <Image
                                    className="user-info-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/fashion.svg"
                                    }
                                  />
                                  <span>
                                    {
                                      userDetails.data.user.selected_category
                                        .name
                                    }
                                  </span>
                                </Link>
                              </Media>
                            )}
                          {userDetails.data.user.date_of_birth && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/date-icon.svg"
                                  }
                                />
                                <span>
                                  {userDetails.data.user.date_of_birth}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {userDetails.data.user.gender && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/gender.svg"
                                  }
                                />
                                <span>
                                  {userDetails.data.user.gender_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {userDetails.data.user
                            .EmailShareButtoneyes_color_formatted && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/eye.svg"
                                  }
                                />
                                <span>
                                  {userDetails.data.user.eyes_color_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {userDetails.data.user.height_formatted && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/scale.svg"
                                  }
                                />
                                <span>
                                  {userDetails.data.user.height_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                          {userDetails.data.user.weight_formatted && (
                            <Media as="li">
                              <Link to="#">
                                <Image
                                  className="user-info-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/weight.svg"
                                  }
                                />
                                <span>
                                  {userDetails.data.user.weight_formatted}
                                </span>
                              </Link>
                            </Media>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-display">
                    {userDetails.data.is_block_user === 0 ? (
                      <div className="sidebar-links">
                        <ul className="list-unstyled">
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={() => {
                                if (localStorage.getItem("userId")) {
                                  setRequestVideoCall(true);
                                } else {
                                  const notificationMessage =
                                    getErrorNotificationMessage(
                                      t("login_to_continue")
                                    );
                                  props.dispatch(
                                    createNotification(notificationMessage)
                                  );
                                }
                              }}
                            >
                              <span>
                                <Image
                                  className="sidebar-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/video-call-1.svg"
                                  }
                                />
                              </span>
                              {t("video_call")}
                            </Link>
                          </Media>
                          {/* <Media as="li">
                            <Link
                              to="#"
                              onClick={() => {
                                if (localStorage.getItem("userId")) {
                                  setRequestAudioCall(true);
                                } else {
                                  const notificationMessage =
                                    getErrorNotificationMessage(
                                      t("login_to_continue")
                                    );
                                  props.dispatch(
                                    createNotification(notificationMessage)
                                  );
                                }
                              }}
                            >
                              <span>
                                <Image
                                  className="sidebar-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/audio-call.svg"
                                  }
                                />
                              </span>
                              {t("voice_call")}
                            </Link>
                          </Media> */}
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={() => {
                                if (localStorage.getItem("userId")) {
                                  setSendTip(true);
                                } else {
                                  const notificationMessage =
                                    getErrorNotificationMessage(
                                      t("login_to_continue")
                                    );
                                  props.dispatch(
                                    createNotification(notificationMessage)
                                  );
                                }
                              }}
                            >
                              <span>
                                <Image
                                  className="sidebar-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/icons/wallet-dollar.svg"
                                  }
                                />
                              </span>
                              {t("tip_me")}
                            </Link>
                          </Media>
                          {/* <Media as="li">
                            <Link to="#" onClick={handleShareClick}>
                              <span>
                                <Image
                                  className="sidebar-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/share-theme.svg"
                                  }
                                />
                              </span>
                              {t("share")}
                            </Link>
                          </Media> */}
                          {userDetails.data.payment_info
                            .unsubscribe_btn_status == 1 && (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleChatUser(
                                    event,
                                    userDetails.data.user.user_id
                                  )
                                }
                              >
                                <span>
                                  <Image
                                    className="sidebar-links-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/message-1.svg"
                                    }
                                  />
                                </span>
                                {t("message")}
                              </Link>
                            </Media>
                          )}
                        </ul>
                      </div>
                    ) : null}
                    {/* {userDetails.data.youtube_link ||
                    userDetails.data.pinterest_link ||
                    userDetails.data.linkedin_link ||
                    userDetails.data.snapchat_link ||
                    userDetails.data.twitter_link ||
                    userDetails.data.instagram_link ||
                    userDetails.data.amazon_wishlist ||
                    userDetails.data.facebook_link ||
                    userDetails.data.twitch_link ||
                    userDetails.data.website ? (
                      <div className="sidebar-social-links">
                        <ul className="list-unstyled">
                          {userDetails.data.youtube_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.youtube_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/you-tube.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.pinterest_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.pinterest_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/pintrest.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.linkedin_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.linkedin_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/linked-in.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.snapchat_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.snapchat_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/snap-chat.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.twitter_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.twitter_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/twitter.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.instagram_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.instagram_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/instagram.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.amazon_wishlist && (
                            <Media as="li">
                              <a
                                href={userDetails.data.amazon_wishlist}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/amazon.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.facebook_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.facebook_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/facebook.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.twitch_link && (
                            <Media as="li">
                              <a
                                href={userDetails.data.twitch_link}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/twitch.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                          {userDetails.data.website && (
                            <Media as="li">
                              <a
                                href={userDetails.data.website}
                                target="_blank"
                              >
                                <Image
                                  className="sidebar-social-links-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/icon/website.png"
                                  }
                                />
                              </a>
                            </Media>
                          )}
                        </ul>
                      </div>
                    ) : null} */}
                  </div>
                  {userDetails.data.is_block_user === 0 ? (
                    <div className="profile-tab-sec">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="all"
                      >
                        <Row>
                          <Col sm={12}>
                            <Nav
                              variant="pills"
                              className={
                                userDetails.data.user.is_content_creator == 2
                                  ? "grid-five-col"
                                  : "grid-four-col"
                              }
                            >
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="all"
                                  onClick={(event) =>
                                    setActiveSection(event, "all")
                                  }
                                >
                                  <span>
                                    <Image
                                      className="profile-post-tab-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/new-home/icon/all-post-1.svg"
                                      }
                                    />
                                  </span>
                                  <span className="resp-display-none">
                                    {t("all")}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="free"
                                  onClick={(event) =>
                                    setActiveSection(event, "free")
                                  }
                                >
                                  <span>
                                    <Image
                                      className="profile-post-tab-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/new-home/icon/image-post-1.svg"
                                      }
                                    />
                                  </span>
                                  <span className="resp-display-none">
                                    {t("free")}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="hidden"
                                  onClick={(event) =>
                                    setActiveSection(event, "hidden")
                                  }
                                >
                                  <span>
                                    <Image
                                      className="profile-post-tab-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/new-home/icon/video-post-1.svg"
                                      }
                                    />
                                  </span>
                                  <span className="resp-display-none">
                                    {t("hidden")}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item>
                              <Nav.Link
                                eventKey="audio"
                                onClick={(event) =>
                                  setActiveSection(event, "audio")
                                }
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/audio-post-1.svg"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">Musics</span>
                              </Nav.Link>
                            </Nav.Item> */}
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="payperview"
                                  onClick={(event) =>
                                    setActiveSection(event, "payperview")
                                  }
                                >
                                  <span>
                                    <Image
                                      className="profile-post-tab-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/my-profile/view-icon.svg"
                                      }
                                    />
                                  </span>
                                  <span className="resp-display-none">
                                    {t("pay_per_view")}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                              {/* {userDetails.data.user.is_content_creator == 2 && (
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="product"
                                  onClick={(event) =>
                                    setActiveSection(event, "product")
                                  }
                                >
                                  <span>
                                    <Image
                                      className="profile-post-tab-icon"
                                      src={
                                        window.location.origin +
                                        "/assets/images/new-home/icon/store-icon-1.svg"
                                      }
                                    />
                                  </span>
                                  <span className="resp-display-none">Store</span>
                                </Nav.Link>
                              </Nav.Item>
                            )} */}
                            </Nav>
                          </Col>
                          {activeSec === "product" ? (
                            <Col md={12}>
                              <ModelProfileStoreSec
                                activeSec={activeSec}
                                setActiveSec={setActiveSec}
                                products={props.products}
                                otherUserUniquId={props.match.params.username}
                              />
                            </Col>
                          ) : activeSec === "payperview" ? (
                            <UserPayPerView
                              otherUserUniquId={props.match.params.username}
                            />
                          ) : (
                            <AllPost
                              activeSec={activeSec}
                              userPosts={props.userPosts}
                              fetchMoreData={fetchMorePost}
                              userPostAlbum={props.userPostAlbum}
                              fetchMorePostAlbum={fetchMorePostAlbum}
                              isProfile={true}
                              userDetails={userDetails}
                              subscriptionPayment={subscriptionPayment}
                            />
                          )}
                        </Row>
                      </Tab.Container>
                    </div>
                  ) : (
                    <h4>{t("unblock_content")}</h4>
                  )}
                </div>
              </div>
            </div>
          )}
        </Container>
      </div>

      {userDetails.loading ? (
        t("loading")
      ) : localStorage.getItem("userId") !== "" &&
        localStorage.getItem("userId") !== null &&
        localStorage.getItem("userId") !== undefined ? (
        <>
          {/* <SendTipModal
                            sendTip={sendTip}
                            closeSendTipModal={closeSendTipModal}
                            username={userDetails.data.user.username}
                            userPicture={userDetails.data.user.picture}
                            name={userDetails.data.user.name}
                            post_id={null}
                            user_id={userDetails.data.user.user_id}
                        /> */}
          {sendTip ? (
            <SendTipPaymentModal
              paymentsModal={sendTip}
              closepaymentsModal={closeSendTipModal}
              user_id={userDetails.data.user.user_id}
            />
          ) : null}
          {/* <PaymentModal
                            subscrptionPayment={subscrptionPayment}
                            closePaymentModal={closePaymentModal}
                            userPicture={userDetails.data.user.picture}
                            name={userDetails.data.user.name}
                            user_unique_id={userDetails.data.user.user_unique_id}
                            subscriptionData={subscriptionData}
                            username={userDetails.data.user.username}
                        /> */}
          {subscrptionPayment ? (
            <SubscriptionPaymentModal
              paymentsModal={subscrptionPayment}
              closepaymentsModal={closePaymentModal}
              name={userDetails.data.user.name}
              user_unique_id={userDetails.data.user.user_unique_id}
              subscriptionData={subscriptionData}
            />
          ) : null}
          <PrivateCallModal
            requestVideoCall={requestVideoCall}
            closePrivateCallModal={closePrivateCallModal}
            username={userDetails.data.user.username}
            userPicture={userDetails.data.user.picture}
            videoAmount={userDetails.data.user.video_call_amount_formatted}
            name={userDetails.data.user.name}
            post_id={null}
            user_id={userDetails.data.user.user_id}
          />
          <PrivateAudioCallModal
            requestAudioCall={requestAudioCall}
            closePrivateCallModal={closePrivateCallModal}
            username={userDetails.data.user.username}
            userPicture={userDetails.data.user.picture}
            AudioAmount={userDetails.data.user.audio_call_amount_formatted}
            name={userDetails.data.user.name}
            post_id={null}
            user_id={userDetails.data.user.user_id}
          />
        </>
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  comments: state.comment.comments,
  chat: state.chat,
  userDetails: state.otherUser.userDetails,
  userPosts: state.otherUser.userPosts,
  products: state.userProducts.otherModelProducts,
  userPostAlbum: state.postAlbum.userPostAlbum,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SingleProfile));
