import React, { useState } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import "./NewSettings.css";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";
import { useEffect } from "react";
import SettingsSidebar from "./SettingsSidebar";
import {
    saveShockTooltipStatusStart,
} from "../../../store/actions/UserAction";

const GeneralSettingsIndex = (props) => {

    const [profileInputData, setProfileInputData] = useState({});

    const [chatToolTip, setChatToolTip] = useState(0);
    const [shockBtn, setShockBtn] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();
        props.dispatch(
            saveShockTooltipStatusStart(profileInputData)
        );
    }

    const handleChange = (event) => {
        setChatToolTip(chatToolTip == 0 ? 1 : 0)
        setProfileInputData({
            ...profileInputData,
            tooltip_visibility: chatToolTip == 0 ? 1 : 0,
        });
    };

    const handleShockChange = (event) => {
        setShockBtn(shockBtn == 0 ? 1 : 0)
        setProfileInputData({
            ...profileInputData,
            shock_btn_visibility: shockBtn == 0 ? 1 : 0,
        });
    };

    useEffect(() => {
        if (!props.profile.loading) {
            setProfileInputData({
                ...profileInputData,
                shock_btn_visibility: props.profile.data.shock_btn_visibility,
                tooltip_visibility: props.profile.data.tooltip_visibility,
                tooltip_notes: props.profile.data.tooltip_notes,
                shock_url: props.profile.data.shock_url == 'null' ? '' : props.profile.data.shock_url,
            });
            setChatToolTip(props.profile.data.tooltip_visibility);
            setShockBtn(props.profile.data.shock_btn_visibility);
        }
    }, [!props.profile.loading]);

    return (
        <>
            <div className="new-settings-sec new-change-password">
                <Container>
                    <div className="new-settings-box">
                        <SettingsSidebar />
                        <div className="new-settings-main-wrapper">
                            <div className="new-changes-password-box">
                                <div className="settings-personal-info-card">
                                    <div className="settings-personal-info-header">
                                        <h3>{t('other_settings')}</h3>
                                    </div>
                                    <Form className="other-setting-form change-password-sec edit-profile-form">
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group className="other-setting-flex mb-0">
                                                    <Form.Label>{t('shock_button')}</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="change-enable"
                                                        checked={shockBtn}
                                                        onChange={(event) => handleShockChange(event)}
                                                    />
                                                </Form.Group>
                                                <div className="mb-0">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t('redirection_url')}
                                                        value={profileInputData.shock_url}
                                                        name="shock_url"
                                                        onChange={(event) => {
                                                            setProfileInputData({
                                                                ...profileInputData,
                                                                shock_url: event.currentTarget.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            {/* {localStorage.getItem("is_content_creator") == 2 ?
                                                <Col md={6}>
                                                    <Form.Group className="other-setting-flex mb-0">
                                                        <Form.Label>Chat Tooltip</Form.Label>
                                                        <Form.Check
                                                            type="switch"
                                                            id="change-enable-1"
                                                            checked={chatToolTip}
                                                            onChange={(event) => handleChange(event)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            value={profileInputData.tooltip_notes}
                                                            placeholder="Tooltip Text"
                                                            name="tooltip_notes"
                                                            onChange={(event) => {
                                                                setProfileInputData({
                                                                    ...profileInputData,
                                                                    tooltip_notes: event.currentTarget.value,
                                                                });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            : ''} */}
                                        </Row>
                                        <Row>
                                            <Col sm={12} xs={12} md={12}>
                                                <div className="settings-btn-sec">
                                                    <Button
                                                        className="settings-submit-btn"
                                                        onClick={handleSubmit}
                                                        disabled={props.saveGeneralSettings.buttonDisable}
                                                    >
                                                        {props.saveGeneralSettings.loadingButtonContent !== null
                                                            ? props.saveGeneralSettings.loadingButtonContent
                                                            : t("save")}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    profile: state.users.profile,
    saveGeneralSettings: state.users.saveGeneralSettings,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(GeneralSettingsIndex));