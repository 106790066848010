import {
  FETCH_CHAT_USERS_START,
  FETCH_CHAT_USERS_SUCCESS,
  FETCH_CHAT_USERS_FAILURE,
  FETCH_CHAT_MESSAGE_START,
  FETCH_CHAT_MESSAGE_SUCCESS,
  FETCH_CHAT_MESSAGE_FAILURE,
  ADD_MESSAGE_CONTENT,
  SAVE_CHAT_USERS_START,
  SAVE_CHAT_USERS_SUCCESS,
  SAVE_CHAT_USERS_FAILURE,
  ADD_MESSAGE_CONTENT_START,
  ADD_MESSAGE_CONTENT_SUCCESS,
  FETCH_CHAT_ASSET_MEDIA_FILES_START,
  FETCH_MORE_CHAT_ASSET_MEDIA_FILES_START,
  FETCH_CHAT_ASSET_MEDIA_FILES_SUCCESS,
  FETCH_CHAT_ASSET_MEDIA_FILES_FAILURE,
  SAVE_CHAT_TOOLTIP_START,
  SAVE_CHAT_TOOLTIP_SUCCESS,
  SAVE_CHAT_TOOLTIP_FAILURE,
  UPDATE_CHAT_USERS_START,
  UPDATE_CHAT_USERS_SUCCESS,
  UPDATE_CHAT_USERS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  chatUsers: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
  },
  saveChatUser: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
  messages: {
    data: {
      messages: [],
      user: {},
    },
    loading: false,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
    skip: 0,
    length: 0,
    fetchMoreFlag: null,
    loadMoreLoading: null,
  },
  chatAssetMedias: {
    data: {
      chat_assets: [],
      total: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  saveChatTootip: {
    data: {},
    loading: true,
    error: false,
    inputData: {},
    loadingButtonContent: null,
    buttonDisable: false,
  },
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHAT_USERS_START:
      return {
        ...state,
        chatUsers: {
          inputData: action.data,
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CHAT_USERS_SUCCESS:
      return {
        ...state,
        chatUsers: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
        },
      };
    case FETCH_CHAT_USERS_FAILURE:
      return {
        ...state,
        chatUsers: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
        },
      };
    case FETCH_CHAT_MESSAGE_START:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
          skip: 0,
          length: 0,
          fetchMoreFlag: null
        },
      };
    case FETCH_CHAT_MESSAGE_SUCCESS:
      let user_list = !state.chatUsers.loading && state.chatUsers.data.users.map((user) => user.to_user_id===action.data.user.user_id ? 
      { ...user, unread_message_count:0 } : user);
      return {
        ...state,
        messages: {
          ...state.messages,
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
          skip: action.data.messages.length + state.messages.skip,
          length: action.data.messages.length,
          fetchMoreFlag: action.data.messages.length == action.data.total ? false : true,
        },
        chatUsers: {
          ...state.chatUsers,
          data: {
            ...state.chatUsers.data,
            users: user_list,
            total: state.chatUsers.data.total
            
          },
        }
      };
    case FETCH_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
          fetchMoreFlag: action.data.total > 0 ? true : false,
        },
      };

    case ADD_MESSAGE_CONTENT_START:
      return {
        ...state,
        messages: {
          // data: {
          //   messages: [...state.messages.data.messages],
          // },
          ...state.messages,
          loading: false,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
          skip: state.messages.skip,
          length: state.messages.length,
          loadMoreLoading: true
        },
      };

    case ADD_MESSAGE_CONTENT:
      let first = !state.chatUsers.loading && state.chatUsers.data.users.filter((user)=> user.to_user_id === action.data[0].to_user_id)
      let users_list = !state.chatUsers.loading && state.chatUsers.data.users.filter((user)=>user.to_user_id!==action.data[0].to_user_id)
      return {
        ...state,
        messages: {
          ...state.messages,
          data: {
            messages: [...state.messages.data.messages, ...action.data],
            user: { ...state.messages.data.user },
          },
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
        chatUsers: {
          ...state.chatUsers,
          data: {
            ...state.chatUsers.data,
            users: [ first, ...users_list],
            total: state.chatUsers.data.total
            
          },
        }
      };
    case ADD_MESSAGE_CONTENT_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          data: {
            messages: [...action.data.messages, ...state.messages.data.messages],
            user: { ...state.messages.data.user },
          },
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
          skip: action.data.messages.length + state.messages.skip,
          length: action.data.messages.length + state.messages.length,
          fetchMoreFlag: state.messages.length == action.data.total ? false : true,
          loadMoreLoading: false
        },
      };
    case SAVE_CHAT_USERS_START:
      return {
        ...state,
        saveChatUser: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SAVE_CHAT_USERS_SUCCESS:
      return {
        ...state,
        saveChatUser: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SAVE_CHAT_USERS_FAILURE:
      return {
        ...state,
        saveChatUser: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case FETCH_CHAT_ASSET_MEDIA_FILES_START:
      return {
        ...state,
        chatAssetMedias: {
          data: {
            chat_assets: [],
            total: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };

    case FETCH_MORE_CHAT_ASSET_MEDIA_FILES_START:
      return state;
    case FETCH_CHAT_ASSET_MEDIA_FILES_SUCCESS:
      return {
        ...state,
        chatAssetMedias: {
          data: {
            chat_assets: [...state.chatAssetMedias.data.chat_assets, ...action.data.chat_assets],
            total: action.data.total,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_CHAT_ASSET_MEDIA_FILES_FAILURE:
      return {
        ...state,
        chatAssetMedias: {
          data: state.chatAssetMedias.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SAVE_CHAT_TOOLTIP_START:
      return {
        ...state,
        saveChatTooltip: {
          data: {},
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case SAVE_CHAT_TOOLTIP_SUCCESS:
      return {
        ...state,
        saveChatTooltip: {
          data: action.data,
          loading: false,
          error: false,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    case SAVE_CHAT_TOOLTIP_FAILURE:
      return {
        ...state,
        saveChatTooltip: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    
    case UPDATE_CHAT_USERS_START:
      let users = !state.chatUsers.loading && state.chatUsers.data.users.filter((user)=>user.chat_user_id!==action.data.chat_user.chat_user_id)
      return {
        ...state,
        chatUsers: {
          ...state.chatUsers,
          data: {
            users: [action.data.chat_user, ...users],
            total: state.chatUsers.data.total
          },
          // loading: false,
          // error: false,
          // inputData: action.data,
          // loadingButtonContent: "Loading... Please wait.",
          // buttonDisable: true,
        },
      };
    case UPDATE_CHAT_USERS_SUCCESS:
      return {
        ...state,
        saveChatUser: {
          ...state.chatUsers,
          data: {
            users: [...action.data, ...state.chatUsers.data.users.filter((user)=>user.chat_user_id!==action.data.chat_user_id)],
            total: state.chatUsers.data.total
          },
          loading: true,
          error: false,
          inputData: action.data,
          loadingButtonContent: "Loading... Please wait.",
          buttonDisable: true,
        },
      };
    case UPDATE_CHAT_USERS_FAILURE:
      return {
        ...state,
        saveChatUser: {
          data: {},
          loading: true,
          error: action.error,
          inputData: {},
          loadingButtonContent: null,
          buttonDisable: false,
        },
      };
    
    default:
      return state;
  }
};

export default ChatReducer;
