import React, { useState, useEffect, useRef } from "react";
import { Modal, Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddNewContentModal from "./AddNewContentModal";
import { connect } from 'react-redux';
import { translate, t } from "react-multi-lang";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import InfiniteScroll from "react-infinite-scroll-component"
import NoDataFound from "../../NoDataFound/NoDataFound";
import {
  fetchSinglePostAlbumStart,
  fetchMoreSinglePostAlbumStart,
  postAlbumFileRemoveStart
} from "../../../store/actions/PostAlbumAction";
import ReactPlayer from "react-player/lazy";
import FancyBox from "../../NewHome/NewSingleView/FancyBox";
import CustomLazyLoad from "../../helper/CustomLazyLoad";

const PostAlbumFiles = (props) => {

  const [addNewContent, setAddNewContent] = useState(false);

  const closeAddNewContentModal = () => {
    setAddNewContent(false);
  };

  useEffect(() => {
    props.dispatch(
      fetchSinglePostAlbumStart({
        post_album_unique_id: props.post_album_unique_id,
        skip: 0,
        take: 11,
      })
    );
  }, []);

  const fetchMorePostAlbumFiles = () => {
    props.dispatch(
      fetchMoreSinglePostAlbumStart({
        post_album_unique_id: props.post_album_unique_id,
        skip: props.singlePostAlbum.data.post_album_files.length,
        take: 12,
      })
    );
  }

  const assetImage = useRef(null);

  return (
    <>
      <div className="my-profile-cc-sec">
        {props.singlePostAlbum.loading ?

          <div className="payper-view-box">
            {
              [...Array(8)].map(() =>
                <Skeleton
                  className="profile-post-card-loader" />
              )
            }
          </div>
          :

          <>
            {Object.keys(props.singlePostAlbum.data).length > 0 && (
              <div className="my-profile-cc-header-sec">
                <div className="my-profile-cc-user-info">
                  <h4>{props.singlePostAlbum.data.post_album.name}</h4>
                  <p>{props.singlePostAlbum.data.post_album.description}</p>
                </div>
                <div className="my-profile-cc-img-video-count-display">
                  <div className="my-profile-cc-img-video-count">
                    <Image
                      className="my-profile-cc-img-video-count-icon"
                      src={
                        window.location.origin + "/assets/images/my-profile/image-icon.svg"
                      }
                    />
                    <span>{props.singlePostAlbum.data.post_album.total_images}</span>
                  </div>
                  <div className="my-profile-cc-img-video-count">
                    <Image
                      className="my-profile-cc-img-video-count-icon"
                      src={
                        window.location.origin + "/assets/images/my-profile/video-icon.svg"
                      }
                    />
                    <span>{props.singlePostAlbum.data.post_album.total_videos}</span>
                  </div>
                  {props.singlePostAlbum.data.post_album.creator_btn_status == 1 ?
                    <>
                      <div className="my-profile-cc-img-video-count">
                        <Image
                          className="my-profile-cc-img-video-count-icon"
                          src={
                            window.location.origin + "/assets/images/my-profile/amount.svg"
                          }
                        />
                        <span>{props.singlePostAlbum.data.post_album.amount_formatted}</span>
                      </div>
                      <div className="my-profile-cc-img-video-count">
                        <Image
                          className="my-profile-cc-img-video-count-icon"
                          src={
                            window.location.origin + "/assets/images/my-profile/revenue.svg"
                          }
                        />
                        <span>{props.singlePostAlbum.data.post_album.user_amount_formatted}</span>
                      </div>
                    </> : null}
                </div>
              </div>
            )}
            <FancyBox delegate={"[data-fancybox-asset]"} options={{ groupAll: true }}>
              <InfiniteScroll
                dataLength={props.singlePostAlbum.data.post_album_files.length}
                next={fetchMorePostAlbumFiles}
                hasMore={props.singlePostAlbum.data.post_album_files.length < props.singlePostAlbum.data.total}
                loader={
                  <div className="payper-view-user-box">
                    {[...Array(4)].map(() =>
                      <Skeleton
                        className="profile-post-card-loader" />
                    )}
                  </div>
                }
                style={{ height: 'auto', overflow: 'hidden' }}
              >
                <div className="my-profile-cc-box">

                  {props.singlePostAlbum.data.post_album.creator_btn_status == 1 ?

                    <div className="my-profile-cc-add-new-card" onClick={() => setAddNewContent(true)}>
                      <Image
                        className="my-profile-cc-add-new-icon"
                        src={
                          window.location.origin + "/assets/images/my-profile/add-content.svg"
                        }
                      />
                      <span>{t('upload_file')}</span>
                    </div>
                    : ''}

                  {props.singlePostAlbum.data.post_album_files.length > 0 ?
                    props.singlePostAlbum.data.post_album_files.map((post_album_file, index) =>
                      <>

                        {post_album_file.file_type == 'image' ?
                          <div className="my-profile-cc-card" key={index}>

                            <CustomLazyLoad
                              src={post_album_file.file}
                              className="my-profile-cc-img"
                              data-fancybox-asset
                            />

                            <div className="my-profile-top-icon-sec">
                              <Image
                                className="my-profile-top-icon"
                                src={window.location.origin + "/assets/images/my-profile/image-white-icon.svg"}
                              />
                            </div>
                            {props.singlePostAlbum.data.post_album.creator_btn_status == 1 ?
                              <div className="my-profile-cc-delete-btn-sec">
                                <Button
                                  className="cc-delete-btn"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        t("delete_album_file_confirmation")
                                      )
                                    ) {
                                      props.dispatch(
                                        postAlbumFileRemoveStart({
                                          post_album_file_id:
                                            post_album_file.post_album_file_id,
                                          post_album_unique_id: props.post_album_unique_id,
                                        })
                                      );
                                    }
                                  }}
                                >
                                  <Image
                                    className="my-profile-bottom-icon"
                                    src={
                                      window.location.origin + "/assets/images/my-profile/delete-icon.svg"
                                    }
                                  />
                                  Delete
                                </Button>
                              </div>
                              : ''}
                          </div>
                          : post_album_file.file_type == "video" ?
                            <div className="my-profile-cc-card">

                              <Image
                                className="my-profile-cc-img"
                                src={post_album_file.preview_file}
                                data-fancybox-asset
                                ref={assetImage}
                                href={post_album_file.file}
                              />

                              <div className="my-profile-top-icon-sec" onClick={() => assetImage.current.click()}>
                                <Image
                                  className="my-profile-top-icon"
                                  src={window.location.origin + "/assets/images/my-profile/video-white-icon.svg"}
                                />
                              </div>
                              {props.singlePostAlbum.data.post_album.creator_btn_status == 1 ?
                                <div className="my-profile-cc-delete-btn-sec">
                                  <Button
                                    className="cc-delete-btn"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          t("delete_album_file_confirmation")
                                        )
                                      ) {
                                        props.dispatch(
                                          postAlbumFileRemoveStart({
                                            post_album_file_id:
                                              post_album_file.post_album_file_id,
                                            post_album_unique_id: props.post_album_unique_id,
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    <Image
                                      className="my-profile-bottom-icon"
                                      src={
                                        window.location.origin + "/assets/images/my-profile/delete-icon.svg"
                                      }
                                    />
                                    Delete
                                  </Button>
                                </div>
                                : ''}
                            </div>
                            : null}
                      </>
                    ) : ''}

                </div>
              </InfiniteScroll>
              {props.singlePostAlbum.data.post_album.creator_btn_status == 0 && props.singlePostAlbum.data.post_album_files.length == 0 ?
                <NoDataFound />
                : null}
            </FancyBox>
          </>
        }
      </div>
      {!props.singlePostAlbum.loading && addNewContent ?
        <AddNewContentModal
          addNewContent={addNewContent}
          closeAddNewContentModal={closeAddNewContentModal}
          setAddNewContent={setAddNewContent}
          postAlbumId={props.singlePostAlbum.data.post_album.post_album_id}
          postAlbumUniqueId={props.post_album_unique_id}
        />
        : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  singlePostAlbum: state.postAlbum.singlePostAlbum,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(PostAlbumFiles));
