import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  deletePostAlbumFailure,
  deletePostAlbumSuccess,
  savePostAlbumFailure,
  savePostAlbumSuccess,
  fetchPostAlbumForOwnerSuccess,
  fetchPostAlbumForOwnerFailure,
  fetchPostAlbumForOwnerStart,
  fetchSinglePostAlbumSuccess,
  fetchSinglePostAlbumFailure,
  postAlbumFileRemoveFailure,
  postAlbumFileRemoveSuccess,
  postAlbumFileUploadFailure,
  postAlbumFileUploadSuccess,
  fetchSinglePostAlbumStart,
  fetchPostAlbumForOthersSuccess,
  fetchPostAlbumForOthersFailure,
  postAlbumPaymentWalletFailure,
  postAlbumPaymentWalletSuccess,
} from "../actions/PostAlbumAction";
import {
  FETCH_POST_ALBUMS_FOR_OWNER_START,
  SAVE_POST_ALBUM_START,
  DELETE_POST_ALBUM_START,
  FETCH_SINGLE_POST_ALBUM_START,
  POST_ALBUM_FILE_UPLOAD_START,
  POST_ALBUM_FILE_REMOVE_START,
  FETCH_POST_ALBUMS_FOR_OTHERS_START,
  POST_ALBUM_PAYMENT_WALLET_START,
  FETCH_MORE_SINGLE_POST_ALBUM_START,
} from "../actions/ActionConstant";
import {
  checkLogoutStatus,
} from "../actions/ErrorAction";

function* fetchPostAlbumForOwnerAPI(action) {
  try {
    const response = yield api.postMethod("post_albums_for_owner", action.data);
    if (response.data.success) {
      yield put(fetchPostAlbumForOwnerSuccess(response.data.data));
    } else {
      yield put(fetchPostAlbumForOwnerFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPostAlbumForOwnerFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* savePostAlbumAPI(action) {
  try {
    const response = yield api.postMethod("post_albums_save", action.data);
    if (response.data.success) {
      yield put(savePostAlbumSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchPostAlbumForOwnerStart({ append: false }));
    } else {
      yield put(savePostAlbumFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(savePostAlbumFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deletePostAlbumAPI(action) {
  try {
    const response = yield api.postMethod("post_albums_delete", action.data);
    if (response.data.success) {
      yield put(deletePostAlbumSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
      yield put(fetchPostAlbumForOwnerStart({ append: false }));
    } else {
      yield put(deletePostAlbumFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deletePostAlbumFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchSinglePostAlbumAPI(action) {
  try {
    const response = yield api.postMethod("post_albums_view", action.data);
    if (response.data.success) {
      yield put(fetchSinglePostAlbumSuccess(response.data.data));
    } else {
      yield put(fetchSinglePostAlbumFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      //window.location.assign("/profile");
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchSinglePostAlbumFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}


function* postAlbumFileUploadStartAPI(action) {
  try {
    const response = yield api.postMethod("post_album_files_upload", action.data);
    if (response.data.success) {
      yield put(postAlbumFileUploadSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchSinglePostAlbumStart({ post_album_unique_id: action.data.post_album_unique_id }));
    } else {
      yield put(postAlbumFileUploadFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(postAlbumFileUploadFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* postAlbumFileRemoveStartAPI(action) {
  try {
    const response = yield api.postMethod("post_album_files_remove", action.data);
    if (response.data.success) {
      yield put(postAlbumFileRemoveSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
      yield put(fetchSinglePostAlbumStart({ post_album_unique_id: action.data.post_album_unique_id }));
    } else {
      yield put(postAlbumFileRemoveFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(postAlbumFileRemoveFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* fetchPostAlbumForOtherAPI(action) {
  try {
    const response = yield api.postMethod("post_albums", action.data);
    if (response.data.success) {
      yield put(fetchPostAlbumForOthersSuccess(response.data.data));
    } else {
      yield put(fetchPostAlbumForOthersFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(fetchPostAlbumForOthersFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* AlbumPaymentWalletAPI(action) {
  try {
    const response = yield api.postMethod(
      "post_album_payment_by_wallet", action.data
    );
    if (response.data.success) {
      yield put(postAlbumPaymentWalletSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));
    } else {
      yield put(postAlbumPaymentWalletFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(postAlbumPaymentWalletFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_POST_ALBUMS_FOR_OWNER_START, fetchPostAlbumForOwnerAPI)]);
  yield all([yield takeLatest(SAVE_POST_ALBUM_START, savePostAlbumAPI)]);
  yield all([yield takeLatest(DELETE_POST_ALBUM_START, deletePostAlbumAPI)]);
  yield all([yield takeLatest(FETCH_SINGLE_POST_ALBUM_START, fetchSinglePostAlbumAPI)]);
  yield all([yield takeLatest(FETCH_MORE_SINGLE_POST_ALBUM_START, fetchSinglePostAlbumAPI)]);
  yield all([yield takeLatest(POST_ALBUM_FILE_REMOVE_START, postAlbumFileRemoveStartAPI)]);
  yield all([yield takeLatest(POST_ALBUM_FILE_UPLOAD_START, postAlbumFileUploadStartAPI)]);
  yield all([yield takeLatest(FETCH_POST_ALBUMS_FOR_OTHERS_START, fetchPostAlbumForOtherAPI)]);
  yield all([yield takeLatest(POST_ALBUM_PAYMENT_WALLET_START, AlbumPaymentWalletAPI)]);
}
