import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import PaymentMethodCard from "./PaymentMethodCard";
import AddCardModalSec from "./AddCardModalSec";
import PaymentModelMsgSec from "./PaymentModelMsgSec";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";
import {
    postAlbumPaymentWalletStart,
} from "../../../store/actions/PostAlbumAction";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const PostAlbumPaymentModal = (props) => {
    const history = useHistory();

    const nullData = ["", null, undefined, "light"];
    const [skipRender, setSkipRender] = useState(true);

    const [paymentType, setPaymentType] = useState(
        localStorage.getItem("default_payment_method")
    );
    const [selectedCard, setSelectedCard] = useState(null);
    const [showAddCard, setShowAddCard] = useState(false);

    const paypalOnError = (err) => {
        const notificationMessage = getErrorNotificationMessage(err);
        this.props.dispatch(createNotification(notificationMessage));
    };

    const paypalOnSuccess = (payment) => {
        setTimeout(() => {

        }, 1000);
        props.closepaymentsModal();
    };

    const paypalOnCancel = (data) => {
        const notificationMessage = getErrorNotificationMessage(
            "Payment cancelled please try again.."
        );
        this.props.dispatch(createNotification(notificationMessage));
    };
    const handleSubmit = () => {
        if (paymentType === "WALLET")
            props.dispatch(
                postAlbumPaymentWalletStart({
                    post_album_id: props.albumData.post_album_id,
                })
            );
    };

    useEffect(() => {
        if (
            !skipRender &&
            !props.albumPayWallet.loading &&
            Object.keys(props.albumPayWallet.data).length > 0
        ) {
            history.push("/gallery/"+props.albumPayWallet.data.post_album.user_unique_id+"/"+props.albumPayWallet.data.post_album.post_album_unique_id);
            props.closepaymentsModal();
        }
        setSkipRender(false);
    }, [props.albumPayWallet]);

    return (
        <>
            <div className="payment-modal-sec">
                <Modal
                    className={`modal-dialog-center user-list-free-modal payment-modal-res ${nullData.includes(localStorage.getItem("theme"))
                            ? ""
                            : "dark-theme-modal"
                        }`}
                    size="xl"
                    centered
                    show={props.paymentsModal}
                    onHide={props.closepaymentsModal}
                >
                    {/* <Modal.Header closeButton>
            {/* <Modal.Title>User List</Modal.Title> *
          </Modal.Header> */}
          <Modal.Header closeButton>
              <Modal.Title>{t("buy_gallery")}</Modal.Title>
            </Modal.Header>
                    <Modal.Body className="wallet-card-body">
                        {/* <Button
                            className="modal-close"
                            onClick={() => props.closepaymentsModal()}
                        >
                            <i className="fa fa-times" />
                        </Button> */}
                        <div className="payment-modal-body">
                            <Row className="justify-content-between">
                                <PaymentMethodCard
                                    paymentType={paymentType}
                                    setPaymentType={setPaymentType}
                                    selectedCard={selectedCard}
                                    setSelectedCard={setSelectedCard}
                                    setShowAddCard={setShowAddCard}
                                    payAmount={props.albumData.amount}
                                />
                                <Col md={12} xl={5}>
                                    {showAddCard ? (
                                        <AddCardModalSec setShowAddCard={setShowAddCard} />
                                    ) : (
                                        <PaymentModelMsgSec
                                            title={t("folder_payment")}
                                            message={t("folder_payment_note")}
                                            paymentType={paymentType}
                                            amount_formatted={props.albumData.amount_formatted}
                                            amount={props.albumData.amount}
                                            payNowAction={handleSubmit}
                                            paypalOnError={paypalOnError}
                                            paypalOnSuccess={paypalOnSuccess}
                                            paypalOnCancel={paypalOnCancel}
                                            btnDisable={
                                                props.albumPayWallet.ButtonDisable
                                            }
                                            btnText={
                                                props.albumPayWallet.loadingButtonContent
                                                    ? props.albumPayWallet.loadingButtonContent
                                                    : t("pay")
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    albumPayWallet: state.postAlbum.albumPayWallet,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(PostAlbumPaymentModal));
