import React, { useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  saveChatTooltipStart,
} from "../../store/actions/ChatAction";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";

const ChatToolTipModal = (props) => {
  const [inputData, setInputData] = useState({ content: props.toolTipContent });
  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.toolTipContent || inputData.content) {
      props.dispatch(
        saveChatTooltipStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: props.toUserId,
          content: inputData.content,
          type: 'notes',
        })
      );
    } else {
      const notificationMessage = getErrorNotificationMessage(
        "Please fill the notes."
      );
      props.dispatch(createNotification(notificationMessage));
    }
    setInputData({
      ...inputData,
      content: '',
    });
    props.closeToolTipModal();
  };

  const nullData = ["", null, undefined, "light"];

  return (
    <>
      <Modal
        className={`modal-dialog-center sent-tip-modal 
                ${nullData.includes(localStorage.getItem("theme")) ?
            "" : "dark-theme-modal"
          }`}
        size="md"
        centered
        show={props.toolTipModal}
        onHide={props.closeToolTipModal}
      >
        {props.toolTipModal === true ? (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t('set_notes')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="floating-form">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={t('notes')}
                    className="height-auto"
                    defaultValue={
                      props.toolTipContent
                        ? props.toolTipContent
                        : null
                    }
                    onChange={(event) =>
                      setInputData({
                        ...inputData,
                        content: event.currentTarget.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-space-btween">
              {props.toolTipContent ? 
              <Button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  if (
                    window.confirm(
                      t("clear_notes_confirmation")
                    )
                  ) {
                    props.closeToolTipModal();
                    props.dispatch(
                      saveChatTooltipStart({
                        from_user_id: localStorage.getItem("userId"),
                        to_user_id: props.toUserId,
                        content: '',
                        type: 'notes',
                      })
                    );
                  }
                }}
              >
                {t("clear_notes")}
              </Button>
              : ''}
              <div className="margin-l-auto">
              <Button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {t("save")}
              </Button>
              </div>
            </Modal.Footer>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  saveChatTooltip: state.chat.saveChatTooltip,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(ChatToolTipModal));