import {
  FETCH_POST_ALBUMS_FOR_OWNER_START,
  FETCH_POST_ALBUMS_FOR_OWNER_SUCCESS,
  FETCH_POST_ALBUMS_FOR_OWNER_FAILURE,
  SAVE_POST_ALBUM_START,
  SAVE_POST_ALBUM_SUCCESS,
  SAVE_POST_ALBUM_FAILURE,
  DELETE_POST_ALBUM_START,
  DELETE_POST_ALBUM_SUCCESS,
  DELETE_POST_ALBUM_FAILURE,
  FETCH_SINGLE_POST_ALBUM_START,
  FETCH_SINGLE_POST_ALBUM_SUCCESS,
  FETCH_SINGLE_POST_ALBUM_FAILURE,
  POST_ALBUM_FILE_UPLOAD_START,
  POST_ALBUM_FILE_UPLOAD_SUCCESS,
  POST_ALBUM_FILE_UPLOAD_FAILURE,
  POST_ALBUM_FILE_REMOVE_START,
  POST_ALBUM_FILE_REMOVE_SUCCESS,
  POST_ALBUM_FILE_REMOVE_FAILURE,
  FETCH_POST_ALBUMS_FOR_OTHERS_START,
  FETCH_POST_ALBUMS_FOR_OTHERS_SUCCESS,
  FETCH_POST_ALBUMS_FOR_OTHERS_FAILURE,
  POST_ALBUM_PAYMENT_WALLET_START,
  POST_ALBUM_PAYMENT_WALLET_SUCCESS,
  POST_ALBUM_PAYMENT_WALLET_FAILURE,
  FETCH_MORE_SINGLE_POST_ALBUM_START,
} from "./ActionConstant";

export function fetchPostAlbumForOwnerStart(data) {
  return {
    type: FETCH_POST_ALBUMS_FOR_OWNER_START,
    data,
  };
}

export function fetchPostAlbumForOwnerSuccess(data) {
  return {
    type: FETCH_POST_ALBUMS_FOR_OWNER_SUCCESS,
    data,
  };
}

export function fetchPostAlbumForOwnerFailure(error) {
  return {
    type: FETCH_POST_ALBUMS_FOR_OWNER_FAILURE,
    error,
  };
}

export function savePostAlbumStart(data) {
  return {
    type: SAVE_POST_ALBUM_START,
    data,
  };
}

export function savePostAlbumSuccess(data) {
  return {
    type: SAVE_POST_ALBUM_SUCCESS,
    data,
  };
}

export function savePostAlbumFailure(error) {
  return {
    type: SAVE_POST_ALBUM_FAILURE,
    error,
  };
}

export function deletePostAlbumStart(data) {
  return {
    type: DELETE_POST_ALBUM_START,
    data,
  };
}

export function deletePostAlbumSuccess(data) {
  return {
    type: DELETE_POST_ALBUM_SUCCESS,
    data,
  };
}

export function deletePostAlbumFailure(error) {
  return {
    type: DELETE_POST_ALBUM_FAILURE,
    error,
  };
}

export function fetchSinglePostAlbumStart(data) {
  return {
    type: FETCH_SINGLE_POST_ALBUM_START,
    data,
  };
}

export function fetchMoreSinglePostAlbumStart(data) {
  return {
    type: FETCH_MORE_SINGLE_POST_ALBUM_START,
    data,
  }
}

export function fetchSinglePostAlbumSuccess(data) {
  return {
    type: FETCH_SINGLE_POST_ALBUM_SUCCESS,
    data,
  };
}

export function fetchSinglePostAlbumFailure(error) {
  return {
    type: FETCH_SINGLE_POST_ALBUM_FAILURE,
    error,
  };
}

export function postAlbumFileUploadStart(data) {
  return {
    type: POST_ALBUM_FILE_UPLOAD_START,
    data,
  };
}

export function postAlbumFileUploadSuccess(data) {
  return {
    type: POST_ALBUM_FILE_UPLOAD_SUCCESS,
    data,
  };
}

export function postAlbumFileUploadFailure(error) {
  return {
    type: POST_ALBUM_FILE_UPLOAD_FAILURE,
    error,
  };
}

export function postAlbumFileRemoveStart(data) {
  return {
    type: POST_ALBUM_FILE_REMOVE_START,
    data,
  };
}

export function postAlbumFileRemoveSuccess(data) {
  return {
    type: POST_ALBUM_FILE_REMOVE_SUCCESS,
    data,
  };
}

export function postAlbumFileRemoveFailure(error) {
  return {
    type: POST_ALBUM_FILE_REMOVE_FAILURE,
    error,
  };
}

export function fetchPostAlbumForOthersStart(data) {
  return {
    type: FETCH_POST_ALBUMS_FOR_OTHERS_START,
    data,
  };
}

export function fetchPostAlbumForOthersSuccess(data) {
  return {
    type: FETCH_POST_ALBUMS_FOR_OTHERS_SUCCESS,
    data,
  };
}

export function fetchPostAlbumForOthersFailure(error) {
  return {
    type: FETCH_POST_ALBUMS_FOR_OTHERS_FAILURE,
    error,
  };
}

export function postAlbumPaymentWalletStart(data) {
  return {
    type: POST_ALBUM_PAYMENT_WALLET_START,
    data,
  };
}

export function postAlbumPaymentWalletSuccess(data) {
  return {
    type: POST_ALBUM_PAYMENT_WALLET_SUCCESS,
    data,
  };
}

export function postAlbumPaymentWalletFailure(error) {
  return {
    type: POST_ALBUM_PAYMENT_WALLET_FAILURE,
    error,
  };
}