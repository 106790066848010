import {
    FETCH_POST_ALBUMS_FOR_OWNER_START,
    FETCH_POST_ALBUMS_FOR_OWNER_SUCCESS,
    FETCH_POST_ALBUMS_FOR_OWNER_FAILURE,
    SAVE_POST_ALBUM_START,
    SAVE_POST_ALBUM_SUCCESS,
    SAVE_POST_ALBUM_FAILURE,
    DELETE_POST_ALBUM_START,
    DELETE_POST_ALBUM_SUCCESS,
    DELETE_POST_ALBUM_FAILURE,
    FETCH_SINGLE_POST_ALBUM_START,
    FETCH_SINGLE_POST_ALBUM_SUCCESS,
    FETCH_SINGLE_POST_ALBUM_FAILURE,
    POST_ALBUM_FILE_UPLOAD_START,
    POST_ALBUM_FILE_UPLOAD_SUCCESS,
    POST_ALBUM_FILE_UPLOAD_FAILURE,
    POST_ALBUM_FILE_REMOVE_START,
    POST_ALBUM_FILE_REMOVE_SUCCESS,
    POST_ALBUM_FILE_REMOVE_FAILURE,
    FETCH_POST_ALBUMS_FOR_OTHERS_START,
    FETCH_POST_ALBUMS_FOR_OTHERS_SUCCESS,
    FETCH_POST_ALBUMS_FOR_OTHERS_FAILURE,
    POST_ALBUM_PAYMENT_WALLET_START,
    POST_ALBUM_PAYMENT_WALLET_SUCCESS,
    POST_ALBUM_PAYMENT_WALLET_FAILURE,
    FETCH_MORE_SINGLE_POST_ALBUM_START,
} from "../actions/ActionConstant";

const initialState = {
    postAlbum: {
        data: {
            post_albums: [],
            total: 0,
        },
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    saveAlbum: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    deleteAlbum: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    singlePostAlbum: {
        data: {
            post_album_files: [],
            total: 0,
            post_album: {},
        },
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    fileUpload: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    fileRemove: {
        data: {},
        loading: true,
        error: false,
        inputData: {},
        loadingButtonContent: null,
        buttonDisable: false,
    },
    userPostAlbum: {
        data: {
            post_albums: [],
            total: 0,
        },
        loading: true,
        error: false,
        skip: 0,
        length: 0,
    },
    albumPayWallet: {
        inputData: {},
        loading: true,
        error: false,
        success: {},
        buttonDisable: false,
        loadingButtonContent: null,
    },
};

const PostAlbumReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POST_ALBUMS_FOR_OWNER_START:
            return {
                ...state,
                postAlbum: {
                    inputData: action.data,
                    data: {
                        ...state.postAlbum.data,
                        post_albums: action.data.append ? state.postAlbum.data.post_albums : [],
                    },
                    loading: true,
                    error: false,
                    skip: state.postAlbum.skip,
                    length: state.postAlbum.length,
                },
            };
        case FETCH_POST_ALBUMS_FOR_OWNER_SUCCESS:
            return {
                ...state,
                postAlbum: {
                    data: {
                        post_albums: [...state.postAlbum.data.post_albums, ...action.data.post_albums],
                        total: action.data.total,
                    },
                    loading: false,
                    error: false,
                    inputData: {},
                    skip: action.data.post_albums.length + state.postAlbum.skip,
                    length: action.data.post_albums.length,
                },
            };
        case FETCH_POST_ALBUMS_FOR_OWNER_FAILURE:
            return {
                ...state,
                postAlbum: {
                    data: {},
                    loading: true,
                    error: action.error,
                    skip: state.postAlbum.skip,
                    length: state.postAlbum.length,
                },
            };
        case SAVE_POST_ALBUM_START:
            return {
                ...state,
                saveAlbum: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading... Please wait.",
                    buttonDisable: true,
                },
            };
        case SAVE_POST_ALBUM_SUCCESS:
            return {
                ...state,
                saveAlbum: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case SAVE_POST_ALBUM_FAILURE:
            return {
                ...state,
                saveAlbum: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case DELETE_POST_ALBUM_START:
            return {
                ...state,
                deleteAlbum: {
                    data: {},
                    loading: true,
                    error: false,
                    inputData: action.data,
                    loadingButtonContent: "Loading...",
                    buttonDisable: true,
                },
            };
        case DELETE_POST_ALBUM_SUCCESS:
            return {
                ...state,
                deleteAlbum: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case DELETE_POST_ALBUM_FAILURE:
            return {
                ...state,
                deleteAlbum: {
                    data: {},
                    loading: true,
                    error: action.data,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_SINGLE_POST_ALBUM_START:
            return {
                ...state,
                singlePostAlbum: {
                    inputData: action.data,
                    data: {
                        post_album_files: [],
                        total: 0,
                        post_album: {},
                    },
                    loading: true,
                    error: false,
                },
            };
        case FETCH_MORE_SINGLE_POST_ALBUM_START:
            return state;
        case FETCH_SINGLE_POST_ALBUM_SUCCESS:
            return {
                ...state,
                singlePostAlbum: {
                    inputData: action.data,
                    data: {
                        post_album_files: [...state.singlePostAlbum.data.post_album_files, ...action.data.post_album_files],
                        total: action.data.total,
                        post_album: action.data.post_album,
                    },
                    loading: false,
                    error: false,
                },
            };
        case FETCH_SINGLE_POST_ALBUM_FAILURE:
            return {
                ...state,
                singlePostAlbum: {
                    inputData: action.data,
                    data: state.singlePostAlbum.data,
                    loading: false,
                    error: action.error,
                },
            };
        case POST_ALBUM_FILE_UPLOAD_START:
            return {
                ...state,
                fileUpload: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "File Uploading....",
                    buttonDisable: true,
                },
            };
        case POST_ALBUM_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                fileUpload: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case POST_ALBUM_FILE_UPLOAD_FAILURE:
            return {
                ...state,
                fileUpload: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case POST_ALBUM_FILE_REMOVE_START:
            return {
                ...state,
                fileRemove: {
                    inputData: action.data,
                    data: {},
                    loading: true,
                    error: false,
                    loadingButtonContent: "File Deleting....",
                    buttonDisable: true,
                },
            };
        case POST_ALBUM_FILE_REMOVE_SUCCESS:
            return {
                ...state,
                fileRemove: {
                    data: action.data,
                    loading: false,
                    error: false,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case POST_ALBUM_FILE_REMOVE_FAILURE:
            return {
                ...state,
                fileRemove: {
                    data: {},
                    loading: true,
                    error: action.error,
                    inputData: {},
                    loadingButtonContent: null,
                    buttonDisable: false,
                },
            };
        case FETCH_POST_ALBUMS_FOR_OTHERS_START:
            return {
                ...state,
                userPostAlbum: {
                    inputData: action.data,
                    data: {
                        ...state.userPostAlbum.data,
                        post_albums: action.data.append ? state.userPostAlbum.data.post_albums : [],
                    },
                    loading: true,
                    error: false,
                    skip: state.userPostAlbum.skip,
                    length: state.userPostAlbum.length,
                },
            };
        case FETCH_POST_ALBUMS_FOR_OTHERS_SUCCESS:
            return {
                ...state,
                userPostAlbum: {
                    data: {
                        post_albums: [...state.userPostAlbum.data.post_albums, ...action.data.post_albums],
                        total: action.data.total,
                    },
                    loading: false,
                    error: false,
                    inputData: {},
                    skip: action.data.post_albums.length + state.userPostAlbum.skip,
                    length: action.data.post_albums.length,
                },
            };
        case FETCH_POST_ALBUMS_FOR_OTHERS_FAILURE:
            return {
                ...state,
                userPostAlbum: {
                    data: {},
                    loading: true,
                    error: action.error,
                    skip: state.userPostAlbum.skip,
                    length: state.userPostAlbum.length,
                },
            };
        case POST_ALBUM_PAYMENT_WALLET_START:
            return {
                ...state,
                albumPayWallet: {
                    inputData: action.data,
                    loading: true,
                    error: false,
                    success: {},
                    buttonDisable: true,
                    loadingButtonContent: "Processing.. Please wait...",
                },
            };
        case POST_ALBUM_PAYMENT_WALLET_SUCCESS:
            return {
                ...state,
                albumPayWallet: {
                    loading: false,
                    error: false,
                    data: action.data,
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        case POST_ALBUM_PAYMENT_WALLET_FAILURE:
            return {
                ...state,
                albumPayWallet: {
                    loading: true,
                    error: action.error,
                    success: {},
                    buttonDisable: false,
                    loadingButtonContent: null,
                },
            };
        default:
            return state;
    }
};

export default PostAlbumReducer;
