import React, { useEffect, useState } from 'react';
import { Form, Button, Image, Modal, Tab, Row, Col, Nav } from "react-bootstrap";
import { useDropzone } from 'react-dropzone'
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { Form as FORM, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import { postAlbumFileUploadStart } from "../../../store/actions/PostAlbumAction";

const AddNewContentModal = (props) => {

    const [inputData, setInputData] = useState({
        post_album_id: props.postAlbumId,
        post_album_unique_id: props.postAlbumUniqueId,
    });

    const [activeSec, setActiveSec] = useState("image");

    const [files, setFiles] = useState([]);

    const [skipRender, setSkipRender] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        let fileData = [];
        files.map((file, i) => {
            fileData[`file[${i}]`] = file;
        });
        props.dispatch(postAlbumFileUploadStart({
            ...inputData,
            ...fileData,
        }));
    };

    const handleChange = (event, file_type) => {
        let preview_array = [];
        [...event.target.files].forEach((file, key) => {
            preview_array.push(
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
        });
        setFiles(preview_array);
        setInputData({
            ...inputData,
            file_type: file_type,
        });

    };

    useEffect(() => {
        if (
            !skipRender &&
            !props.fileUpload.loading &&
            Object.keys(props.fileUpload.data).length > 0
        ) {
            props.closeAddNewContentModal();
        }
        setSkipRender(false);
    }, [props.fileUpload]);

    const handleRemove = (index) => {
        setFiles(files.filter((item, i) => i !== index));
    };

    const nullData = ["", null, undefined, "light"];

    const setActiveSection = (event, key) => {
        setActiveSec(key);
        setFiles([]);
    };

    return (
        <>
            <Modal
                className={`modal-dialog-center add-new-content-modal 
                ${nullData.includes(localStorage.getItem("theme")) ?
                        "" : "dark-theme-modal"
                    }`}
                size="lg"
                centered
                show={props.addNewContent}
                onHide={props.closeAddNewContentModal}
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('upload_file')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="add-new-content-tab-sec">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="image">
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="pills">
                                            <Nav.Item>
                                                <Nav.Link
                                                    eventKey="image"
                                                    onClick={(event) =>
                                                        setActiveSection(event, "image")
                                                    }
                                                >
                                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1_86)">
                                                            <path d="M17.453 24C17.285 24 17.113 23.979 16.943 23.934L1.48002 19.793C0.420015 19.501 -0.211985 18.403 0.0660152 17.343L2.01702 10.071C2.08902 9.80399 2.36302 9.64899 2.62902 9.71699C2.89602 9.78799 3.05402 10.063 2.98302 10.329L1.03302 17.599C0.894015 18.129 1.21202 18.681 1.74302 18.828L17.2 22.967C17.731 23.107 18.279 22.791 18.417 22.263L19.198 19.369C19.27 19.102 19.544 18.943 19.811 19.016C20.078 19.088 20.235 19.363 20.164 19.629L19.384 22.519C19.149 23.409 18.339 24 17.453 24Z" fill="#1361FF" />
                                                            <path d="M22 18H6C4.897 18 4 17.103 4 16V4C4 2.897 4.897 2 6 2H22C23.103 2 24 2.897 24 4V16C24 17.103 23.103 18 22 18ZM6 3C5.449 3 5 3.449 5 4V16C5 16.551 5.449 17 6 17H22C22.551 17 23 16.551 23 16V4C23 3.449 22.551 3 22 3H6Z" fill="#1361FF" />
                                                            <path d="M9 9C7.897 9 7 8.103 7 7C7 5.897 7.897 5 9 5C10.103 5 11 5.897 11 7C11 8.103 10.103 9 9 9ZM9 6C8.449 6 8 6.449 8 7C8 7.551 8.449 8 9 8C9.551 8 10 7.551 10 7C10 6.449 9.551 6 9 6Z" fill="#1361FF" />
                                                            <path d="M4.57007 16.93C4.44207 16.93 4.31407 16.881 4.21607 16.784C4.02107 16.589 4.02107 16.272 4.21607 16.077L8.93907 11.354C9.50507 10.788 10.4941 10.788 11.0601 11.354L12.4661 12.76L16.3581 8.08999C16.6411 7.75099 17.0571 7.55399 17.5001 7.54999H17.5111C17.9491 7.54999 18.3641 7.73999 18.6501 8.07299L23.8801 14.175C24.0601 14.384 24.0361 14.7 23.8261 14.88C23.6171 15.06 23.3021 15.037 23.1211 14.826L17.8911 8.72399C17.7941 8.61199 17.6601 8.54999 17.5111 8.54999C17.4071 8.54099 17.2241 8.61299 17.1271 8.72999L12.8841 13.821C12.7941 13.929 12.6631 13.994 12.5221 14C12.3801 14.01 12.2451 13.954 12.1461 13.854L10.3531 12.061C10.1641 11.873 9.83507 11.873 9.64607 12.061L4.92307 16.784C4.82607 16.881 4.69807 16.93 4.57007 16.93Z" fill="#1361FF" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1_86">
                                                                <rect width="36" height="36" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg> */}
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 24 24"><g><g><path d="m17.453 24c-.168 0-.34-.021-.51-.066l-15.463-4.141c-1.06-.292-1.692-1.39-1.414-2.45l1.951-7.272c.072-.267.346-.422.612-.354.267.071.425.346.354.612l-1.95 7.27c-.139.53.179 1.082.71 1.229l15.457 4.139c.531.14 1.079-.176 1.217-.704l.781-2.894c.072-.267.346-.426.613-.353.267.072.424.347.353.613l-.78 2.89c-.235.89-1.045 1.481-1.931 1.481z" fill="#6661e7" data-original="#000000"></path></g><g><path d="m22 18h-16c-1.103 0-2-.897-2-2v-12c0-1.103.897-2 2-2h16c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2zm-16-15c-.551 0-1 .449-1 1v12c0 .551.449 1 1 1h16c.551 0 1-.449 1-1v-12c0-.551-.449-1-1-1z" fill="#6661e7" data-original="#000000"></path></g><g><path d="m9 9c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z" fill="#6661e7" data-original="#000000"></path></g><g><path d="m4.57 16.93c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l4.723-4.723c.566-.566 1.555-.566 2.121 0l1.406 1.406 3.892-4.67c.283-.339.699-.536 1.142-.54h.011c.438 0 .853.19 1.139.523l5.23 6.102c.18.209.156.525-.054.705-.209.18-.524.157-.705-.054l-5.23-6.102c-.097-.112-.231-.174-.38-.174-.104-.009-.287.063-.384.18l-4.243 5.091c-.09.108-.221.173-.362.179-.142.01-.277-.046-.376-.146l-1.793-1.793c-.189-.188-.518-.188-.707 0l-4.723 4.723c-.097.097-.225.146-.353.146z" fill="#6661e7" data-original="#000000"></path></g></g></svg> */}
                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_28_1085)">
                                                            <path d="M17.7029 23.0313C17.5139 23.0313 17.3209 23.0074 17.1297 22.9574L1.66538 18.8161C0.472415 18.4871 -0.238601 17.2531 0.0734183 16.0611L1.77341 9.542C1.87742 9.1419 2.28649 8.89891 2.68841 9.00603C3.08851 9.11004 3.32948 9.52002 3.22456 9.92103L1.52438 16.4412C1.41946 16.8431 1.65842 17.2581 2.05851 17.3681L17.5139 21.5073C17.9109 21.6122 18.3209 21.3762 18.4249 20.9812L18.9528 18.8511C19.0529 18.4492 19.4589 18.2051 19.8608 18.3033C20.2629 18.4032 20.5079 18.8092 20.4089 19.2111L19.879 21.3513C19.6118 22.3643 18.6999 23.0313 17.7029 23.0313Z" fill="#7A76EA" />
                                                            <path d="M21.75 17.031H6.24957C5.00863 17.031 3.99951 16.0219 3.99951 14.781V3.28057C3.99951 2.03964 5.00863 1.03052 6.24957 1.03052H21.75C22.9909 1.03052 24.0001 2.03964 24.0001 3.28057V14.781C24.0001 16.0219 22.9909 17.031 21.75 17.031ZM6.24957 2.53055C5.83556 2.53055 5.49955 2.86656 5.49955 3.28057V14.781C5.49955 15.195 5.83556 15.531 6.24957 15.531H21.75C22.164 15.531 22.5 15.195 22.5 14.781V3.28057C22.5 2.86656 22.164 2.53055 21.75 2.53055H6.24957Z" fill="#7A76EA" />
                                                            <path d="M8.99962 8.03098C7.89657 8.03098 6.99951 7.13393 6.99951 6.03087C6.99951 4.92782 7.89657 4.03076 8.99962 4.03076C10.1027 4.03076 10.9995 4.92782 10.9995 6.03087C10.9995 7.13393 10.1027 8.03098 8.99962 8.03098ZM8.99962 5.5308C8.72349 5.5308 8.49955 5.75493 8.49955 6.03087C8.49955 6.30682 8.72349 6.53095 8.99962 6.53095C9.27557 6.53095 9.49951 6.30682 9.49951 6.03087C9.49951 5.75493 9.27557 5.5308 8.99962 5.5308Z" fill="#7A76EA" />
                                                            <path d="M4.76967 16.0109C4.57777 16.0109 4.38569 15.9378 4.23975 15.791C3.94678 15.498 3.94678 15.0228 4.23975 14.7299L8.75982 10.2098C9.44392 9.5257 10.5569 9.5257 11.2399 10.2098L12.4489 11.4189L16.163 6.9607C16.4949 6.56262 16.982 6.33263 17.4991 6.3297C18.055 6.29876 18.5051 6.55163 18.84 6.94477L23.8182 12.7528C24.0881 13.0669 24.0511 13.5409 23.7371 13.8108C23.4222 14.0809 22.9492 14.0439 22.6791 13.7299L17.6991 7.91983C17.633 7.84073 17.552 7.84475 17.507 7.83066C17.4641 7.83066 17.381 7.84366 17.3151 7.92166L13.075 13.0119C12.9399 13.1738 12.743 13.272 12.533 13.2809C12.3189 13.2928 12.117 13.2108 11.9688 13.0619L10.1789 11.2718C10.0518 11.1448 9.94692 11.1448 9.81984 11.2718L5.29978 15.791C5.15365 15.9378 4.96175 16.0109 4.76967 16.0109Z" fill="#7A76EA" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_28_1085">
                                                                <rect width="24" height="24" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    {t('image')}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    eventKey="video"
                                                    onClick={(event) =>
                                                        setActiveSection(event, "video")
                                                    }
                                                >
                                                    {/* <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_28_1030)">
                                                            <path d="M16.2805 0H3.71953C1.66629 0.00238281 0.00238281 1.66629 0 3.71953V16.2805C0.00238281 18.3337 1.66629 19.9976 3.71953 20H16.2805C18.3337 19.9976 19.9976 18.3337 20 16.2805V3.71953C19.9976 1.66629 18.3337 0.00238281 16.2805 0ZM18.6193 3.71953V4.92543H15.1217L12.5059 1.38066H16.2805C17.5716 1.38211 18.6179 2.4284 18.6193 3.71953ZM10.7899 1.38066L13.4058 4.92543H9.21008L6.59422 1.38066H10.7899ZM1.38066 3.71953C1.38211 2.42844 2.4284 1.38215 3.71953 1.38066H4.87832L7.49418 4.92543H1.38066V3.71953ZM16.2805 18.6193H3.71953C2.42844 18.6179 1.38215 17.5716 1.38066 16.2805V6.30613H18.6193V16.2805C18.6179 17.5716 17.5716 18.6179 16.2805 18.6193Z" fill="white" />
                                                            <path d="M13.6831 11.8649L8.22154 8.71174C7.89135 8.52111 7.46916 8.63424 7.27854 8.96443C7.21795 9.0694 7.18604 9.18842 7.18604 9.30963V15.6161C7.18604 15.9973 7.4951 16.3064 7.87635 16.3064C7.99752 16.3064 8.11658 16.2746 8.22154 16.2139L13.6831 13.0607C14.0133 12.8701 14.1264 12.4479 13.9358 12.1177C13.8751 12.0127 13.788 11.9255 13.6831 11.8649ZM8.5667 14.4204V10.5053L11.9572 12.4628L8.5667 14.4204Z" fill="white" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_28_1030">
                                                                <rect width="36" height="36" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg> */}
                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M23.0625 14.5781C23.5803 14.5781 24 14.1584 24 13.6406V5.67188C24 3.60413 22.3177 1.92188 20.25 1.92188H3.75C1.68225 1.92188 0 3.60413 0 5.67188V18.3281C0 20.3959 1.68225 22.0781 3.75 22.0781H20.25C22.3177 22.0781 24 20.3959 24 18.3281C24 17.8103 23.5803 17.3906 23.0625 17.3906C22.5447 17.3906 22.125 17.8103 22.125 18.3281C22.125 19.362 21.2839 20.2031 20.25 20.2031H3.75C2.71613 20.2031 1.875 19.362 1.875 18.3281V5.67188C1.875 4.638 2.71613 3.79688 3.75 3.79688H20.25C21.2839 3.79688 22.125 4.638 22.125 5.67188V13.6406C22.125 14.1584 22.5447 14.5781 23.0625 14.5781Z" fill="#6661E7" />
                                                        <path d="M4.54688 7.54688C5.06464 7.54688 5.48438 7.12714 5.48438 6.60938C5.48438 6.09161 5.06464 5.67188 4.54688 5.67188C4.02911 5.67188 3.60938 6.09161 3.60938 6.60938C3.60938 7.12714 4.02911 7.54688 4.54688 7.54688Z" fill="#6661E7" />
                                                        <path d="M8.29688 7.54688C8.81464 7.54688 9.23438 7.12714 9.23438 6.60938C9.23438 6.09161 8.81464 5.67188 8.29688 5.67188C7.77911 5.67188 7.35938 6.09161 7.35938 6.60938C7.35938 7.12714 7.77911 7.54688 8.29688 7.54688Z" fill="#6661E7" />
                                                        <path d="M12.0469 7.54688C12.5646 7.54688 12.9844 7.12714 12.9844 6.60938C12.9844 6.09161 12.5646 5.67188 12.0469 5.67188C11.5291 5.67188 11.1094 6.09161 11.1094 6.60938C11.1094 7.12714 11.5291 7.54688 12.0469 7.54688Z" fill="#6661E7" />
                                                        <path d="M15.7969 7.54688C16.3146 7.54688 16.7344 7.12714 16.7344 6.60938C16.7344 6.09161 16.3146 5.67188 15.7969 5.67188C15.2791 5.67188 14.8594 6.09161 14.8594 6.60938C14.8594 7.12714 15.2791 7.54688 15.7969 7.54688Z" fill="#6661E7" />
                                                        <path d="M19.5469 7.54688C20.0646 7.54688 20.4844 7.12714 20.4844 6.60938C20.4844 6.09161 20.0646 5.67188 19.5469 5.67188C19.0291 5.67188 18.6094 6.09161 18.6094 6.60938C18.6094 7.12714 19.0291 7.54688 19.5469 7.54688Z" fill="#6661E7" />
                                                        <path d="M4.54688 18.3281C5.06464 18.3281 5.48438 17.9084 5.48438 17.3906C5.48438 16.8729 5.06464 16.4531 4.54688 16.4531C4.02911 16.4531 3.60938 16.8729 3.60938 17.3906C3.60938 17.9084 4.02911 18.3281 4.54688 18.3281Z" fill="#6661E7" />
                                                        <path d="M8.29688 18.3281C8.81464 18.3281 9.23438 17.9084 9.23438 17.3906C9.23438 16.8729 8.81464 16.4531 8.29688 16.4531C7.77911 16.4531 7.35938 16.8729 7.35938 17.3906C7.35938 17.9084 7.77911 18.3281 8.29688 18.3281Z" fill="#6661E7" />
                                                        <path d="M12.0469 18.3281C12.5646 18.3281 12.9844 17.9084 12.9844 17.3906C12.9844 16.8729 12.5646 16.4531 12.0469 16.4531C11.5291 16.4531 11.1094 16.8729 11.1094 17.3906C11.1094 17.9084 11.5291 18.3281 12.0469 18.3281Z" fill="#6661E7" />
                                                        <path d="M15.7969 18.3281C16.3146 18.3281 16.7344 17.9084 16.7344 17.3906C16.7344 16.8729 16.3146 16.4531 15.7969 16.4531C15.2791 16.4531 14.8594 16.8729 14.8594 17.3906C14.8594 17.9084 15.2791 18.3281 15.7969 18.3281Z" fill="#6661E7" />
                                                        <path d="M19.5469 18.3281C20.0646 18.3281 20.4844 17.9084 20.4844 17.3906C20.4844 16.8729 20.0646 16.4531 19.5469 16.4531C19.0291 16.4531 18.6094 16.8729 18.6094 17.3906C18.6094 17.9084 19.0291 18.3281 19.5469 18.3281Z" fill="#6661E7" />
                                                    </svg>
                                                    {t('video')}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Form>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="image">
                                                    <div className="add-new-content-tab-content">
                                                        <div className="upload-img-preview">
                                                            <div className="upload-add-img-sec">
                                                                <div className="upload-btn-wrapper">
                                                                    <button className="btn">
                                                                        {t('upload_images')}
                                                                    </button>
                                                                    <input
                                                                        type="file"
                                                                        accept={"image/*"}
                                                                        onChange={(event) => handleChange(event, 'image')}
                                                                        name="file"
                                                                        multiple
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="new-listing-upload-img-preview">
                                                            {files.map((file, index) =>
                                                                <div className="new-listing-upload-img-preview-card" key={index}>
                                                                    <img
                                                                        className="new-listing-upload-preview-img"
                                                                        src={file.preview}
                                                                        alt="Hero Image"
                                                                    />
                                                                    <div className="remove-icon-upload-preview-img">
                                                                        <Button
                                                                            className="remove-btn"
                                                                            onClick={e => handleRemove(index)}
                                                                        >
                                                                            {/* <i class="material-icons remove-icon">clear</i> */}
                                                                            <Image
                                                                                className="delete-icon-preview"
                                                                                src={
                                                                                    window.location.origin + "/assets/images/my-profile/delete-icon.svg"
                                                                                }
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                            )}
                                                        </div>
                                                        <div className="add-new-content-btn-sec">
                                                            <Button
                                                                className="add-new-content-btn"
                                                                data-dismiss="modal"
                                                                disabled={props.fileUpload.buttonDisable}
                                                                onClick={handleSubmit}
                                                            >
                                                                {t('add_to_folder')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="video">
                                                    <div className="add-new-content-tab-content">
                                                        <div className="upload-img-preview">
                                                            <div className="upload-add-img-sec">
                                                                <div className="upload-btn-wrapper">
                                                                    <button className="btn">
                                                                        {t('upload_videos')}
                                                                    </button>
                                                                    <input
                                                                        type="file"
                                                                        accept={"video/*"}
                                                                        onChange={(event) => handleChange(event, 'video')}
                                                                        name="file"
                                                                        multiple
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="new-listing-upload-video-preview">
                                                            {files.map((file, index) =>
                                                                <div className="new-listing-upload-video-preview-card" key={index}>
                                                                    <h4>{file.name}</h4>
                                                                    <div className="remove-icon-upload-preview-video">
                                                                        <Button className="remove-btn" onClick={e => handleRemove(index)}>

                                                                            {/* <i class="material-icons remove-icon">clear</i> */}
                                                                            <Image
                                                                                className="delete-icon-preview"
                                                                                src={
                                                                                    window.location.origin + "/assets/images/my-profile/delete-icon.svg"
                                                                                }
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                            )}

                                                        </div>
                                                        <div className="add-new-content-btn-sec">
                                                            <Button
                                                                className="add-new-content-btn"
                                                                data-dismiss="modal"
                                                                onClick={handleSubmit}
                                                                disabled={props.fileUpload.buttonDisable}
                                                            >
                                                                {t('add_to_folder')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Form>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    );
};

const mapStateToPros = (state) => ({
    fileUpload: state.postAlbum.fileUpload,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(translate(AddNewContentModal));