import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Badge,
  Image,
} from "react-bootstrap";
import "../../Wallet/Wallet.css";
import NoDataFound from "../../NoDataFound/NoDataFound";
import BillingAccountLoader from "../../Loader/BillingAccountLoader";
import { translate, t } from "react-multi-lang";
import {
  acceptCallStart,
  callRequestReceivedModelStart,
  callHistoryUserStart,
  rejectCallStart,
  endVideoCallStart,
} from "../../../store/actions/PrivateCallAction";
import { Link } from "react-router-dom";
import VideoCallPaymentModal from "../../Model/PaymentModal/VideoCallPaymentModal";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgoraRTC from "../../../hooks/useAgoraRTC";
import io from "socket.io-client";
import configuration from "react-global-configuration";

const rtcclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
let chatSocket;
const VideoCallHistoryIndex = (props) => {
  const {
    localAudioTrack,
    localVideoTrack,
    leaveRtcChannel,
    join,
    joinState,
    remoteUsers,
    isStreamEnded,
    muteAudio,
    muteVideo,
    mediaStatus,
  } = useAgoraRTC(rtcclient);

  const [skip, setSkip] = useState({
    skip: 0,
    take: 12,
  });

  useEffect(() => {
    props.dispatch(callHistoryUserStart());
  }, []);

  const [makePaymentInput, setMakePaymentInput] = useState({
    video_call_request_id: "",
    model_displayname: "",
    model_picture: "",
    amount: "",
    amount_formatted: "",
    model_unique_id: "",
    modelname: "",
  });

  const [
    selectedCallDetailsToMakePayment,
    setSelectedCallDetailsToMakePayment,
  ] = useState(null);

  const [makePaymentModel, setMakePaymentModel] = useState(false);

  const closePaymentModal = () => {
    setMakePaymentModel(false);
  };

  const makePayment = (event, callDetails) => {
    event.preventDefault();
    setMakePaymentInput({
      video_call_request_id: callDetails.video_call_request_id,
      model_displayname: callDetails.model_displayname,
      model_picture: callDetails.model_picture,
      amount: callDetails.amount,
      amount_formatted: callDetails.amount_formatted,
      model_unique_id: callDetails.model_unique_id,
      modelname: callDetails.modelname,
    });
    setSelectedCallDetailsToMakePayment(callDetails);
    setMakePaymentModel(true);
  };

  const endVideoCall = (event, videoCall) => {
    event.preventDefault();
    chatSocketConnect(videoCall.video_call_request_unique_id);
    console.log("out");

    setTimeout(() => {
      console.log("Inside");
      chatSocket.emit("end video call", {
        commonid: "video_call_" + videoCall.video_call_request_unique_id,
        myid: localStorage.getItem("userId"),
      });
      props.dispatch(
        endVideoCallStart({
          video_call_request_id: videoCall.video_call_request_id,
        })
      );
    }, 2000);
  };
  const chatSocketConnect = (id) => {
    console.log("hello");
    // check the socket url is configured
    let chatSocketUrl = configuration.get("configData.chat_socket_url");
    if (chatSocketUrl && id) {
      chatSocket = io(chatSocketUrl, {
        query: `room : video_call_` + id + ``,
      });

      chatSocket.emit("video call update sender", {
        commonid: `video_call_` + id + ``,
      });
    }
  };

  return (
    <>
      <div className="wallet-sec">
        <Container>
          <Row>
            <Col sm={12} md={12}>
              <div className="wallet-header-sec-1">
                <div className="my-profile-new-header">
                  <h1>{t("video_call_history")}</h1>
                </div>
                <Row>
                  <Col sm={12} md={12} xl={9}>
                    {/* <Link
                      className="bookmarkes-list notify-title back-button"
                      onClick={() => props.history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      /> */}
                    {/* <h4 className="bookmarkes-list notify-title back-button">
                      {t("video_call_history")}
                    </h4> */}
                    {/* </Link> */}
                    <p className="text-muted f-2 mb-0 mt-4">
                      {t("video_call_history_note")}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="trans-table-sec">
        <Container>
          {props.callHistoryUser.loading ? (
            <BillingAccountLoader />
          ) : props.callHistoryUser.data.video_call_requests &&
            props.callHistoryUser.data.video_call_requests.length > 0 ? (
            <Row>
              <Col sm={12} md={12}>
                <div className="trans-table">
                  <Table borderedless responsive>
                    <thead>
                      <tr className="bg-white text-muted text-center text-uppercase">
                        <th>{t("s_no")}</th>
                        <th>{t("requested_from")}</th>
                        <th>{t("requested_to")}</th>
                        <th>{t("amount")}</th>
                        <th className="text-nowrap">{t("scheduled")}</th>
                        <th className="text-nowrap">{t("end_time")}</th>
                        <th>{t("status")}</th>
                        <th>{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.callHistoryUser.data.video_call_requests.map(
                        (videoCall, index) => (
                          <tr
                            key={videoCall.video_call_request_id}
                            className="text-center"
                          >
                            <td>{index + 1}</td>
                            <td>
                              <Link to={`/` + videoCall.user_unique_id}>
                                {videoCall.user_displayname}
                              </Link>
                            </td>
                            <td>
                              <Link to={`/` + videoCall.model_unique_id}>
                                {videoCall.model_displayname}
                              </Link>
                            </td>
                            <td>{videoCall.amount_formatted}</td>
                            <td className="text-nowrap">
                              {videoCall.start_time
                                ? videoCall.start_time
                                : "-"}
                            </td>
                            <td className="text-nowrap">
                              {videoCall.end_time ? videoCall.end_time : "-"}
                            </td>
                            <td>{videoCall.call_status_formatted}</td>
                            <td>
                              {videoCall.accept_btn_status == 1 ? (
                                <Button
                                  className="btn btn-sm btn-success mr-3 col-12 mb-2"
                                  onClick={() =>
                                    props.dispatch(
                                      acceptCallStart({
                                        video_call_request_id:
                                          videoCall.video_call_request_id,
                                      })
                                    )
                                  }
                                >
                                  {t("accept")}
                                </Button>
                              ) : (
                                ""
                              )}
                              {videoCall.reject_btn_status == 1 ? (
                                <Button
                                  className="btn btn-sm btn-danger mr-3 col-12 mb-2"
                                  onClick={() =>
                                    props.dispatch(
                                      rejectCallStart({
                                        video_call_request_id:
                                          videoCall.video_call_request_id,
                                      })
                                    )
                                  }
                                >
                                  {t("reject")}
                                </Button>
                              ) : (
                                ""
                              )}
                              {videoCall.payment_btn_status == 1 ? (
                                <Button
                                  className="btn btn-success mr-3 col-12 mb-2"
                                  onClick={(event) =>
                                    makePayment(event, videoCall)
                                  }
                                >
                                  {videoCall.amount > 0
                                    ? t("paynow")
                                    : t("confirm_request")}
                                </Button>
                              ) : (
                                ""
                              )}

                              {videoCall.join_btn_status == 1 ? (
                                <Link
                                  className="btn btn-success mr-3 col-12 mb-2"
                                  to={`/video-call/${videoCall.video_call_request_unique_id}`}
                                >
                                  {t("join_call")}
                                </Link>
                              ) : (
                                ""
                              )}

                              {videoCall.start_btn_status == 1 ? (
                                <Link
                                  className="btn btn-success mr-3 col-12 mb-2"
                                  to={`/video-call/${videoCall.video_call_request_unique_id}`}
                                >
                                  {t("start_call")}
                                </Link>
                              ) : (
                                ""
                              )}

                              {videoCall.end_btn_status == 1 ? (
                                <Button
                                  className="btn btn-danger mr-3 col-12 mb-2"
                                  onClick={(event) =>
                                    endVideoCall(event, videoCall)
                                  }
                                  // onClick={() =>
                                  //   props.dispatch(
                                  //     endVideoCallStart({
                                  //       video_call_request_id:
                                  //         videoCall.video_call_request_id,
                                  //     })
                                  //   )
                                  // }
                                >
                                  {t("end_call")}
                                </Button>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <VideoCallPaymentModal
                video_call_request_id={makePaymentInput.video_call_request_id}
                paymentsModal={makePaymentModel}
                closepaymentsModal={closePaymentModal}
                callDetails={makePaymentInput}
              />
            </Row>
          ) : (
            <NoDataFound />
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  callHistoryUser: state.privateCall.callHistoryUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(VideoCallHistoryIndex));
