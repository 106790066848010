import React, { useEffect, useState, useRef } from "react";
import HeaderIndex from "./Header/HeaderIndex";
import { Notify } from "react-redux-notify";
import LatestFooter from "./Footer/LatestFooter";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { Link, useHistory } from "react-router-dom";
import Draggable from "react-draggable";
import NewFooter from "./Footer/NewFooter";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";

const MainLayout = (props) => {


  let history = useHistory();

  const [skipRender, setSkipRender] = useState(true);

  const [themeState, setThemeState] = useState(
    localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark"
      ? true
      : false
  );

  const toggleClass = () => {
    localStorage.setItem("theme", themeState ? "light" : "dark");
    setThemeState(!themeState);
  };

  useEffect(() => {
    if (localStorage.getItem("userId"))
      props.dispatch(fetchUserDetailsStart());
  }, []);

  useEffect(() => {
    if (!skipRender && !props.profile.loading && props.profile.data.is_email_verified === 0) {
      history.push("/register/verify");
    }
    setSkipRender(false);
  }, [props.profile]);

  const nodeRef = useRef(null);

  const [position2, setPosition2] = useState({ x: 50, y: 50 });

  const [Opacity2, setOpacity2] = useState(false);

  const trackPos2 = (data) => {
    setPosition2({ x: data.x, y: data.y });
  };

  const handleStart2 = () => {
    setOpacity2(true);
  };
  const handleEnd2 = () => {
    setOpacity2(false);
  };

  return (
    <div className={`${themeState ? "dark-mode" : ""}`}>
      <div className="app-admin-wrap layout-sidebar-large">
        <Notify position="TopRight" />
        <HeaderIndex toggleTheme={toggleClass} darkTheme={themeState} />
        <div className="main-content-wrap sidenav-open d-flex flex-column">
          {props.profile.data.shock_btn_visibility == 1 && props.profile.data.shock_url != 'null' ?
            <div className="new-sticky-btn">
              <Draggable nodeRef={nodeRef}
                onDrag={(e, data) => trackPos2(data)}
                onStart={handleStart2}
                onStop={handleEnd2}
                scale={2}>
                <a href={props.profile.data.shock_url} className="shock-btn" ref={nodeRef}
                  style={{ opacity: Opacity2 ? "0.6" : "1" }}>
                  <Image
                    className="shortcut-img"
                    src={
                      window.location.origin + "/assets/images/shortcut-img.svg"
                    }
                  />
                </a>
              </Draggable>
            </div>
            : ''}
          <div className="main-wrap-sec">
            {React.cloneElement(props.children)}
          </div>
          {/* <LatestFooter /> */}
          <NewFooter />
        </div>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MainLayout);
